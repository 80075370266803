import { Component, input } from '@angular/core';

export const BUTTON_SIZES = [
    "sm",
    "md",
    "lg",
] as const;
export type ButtonSize = (typeof BUTTON_SIZES)[number];

/**
 * Simple button component with single icon.
 * Defaults to an outlined button (white bg and grey text without border).
 * Will overwrite any text from the button tag itself.
 * 
 * Usage:
 * 
 * ```html
 * <button evc-icon-button icon="sync" (click)="someMethod()"></button>
 * <button 
 *     evc-icon-button
 *     btnSize="lg"
 *     icon="sync"
 *     [iconSpinOnLoading]="true"
 *     [loading]="someSignal()"
 *     [disabled]="(condition$ | async) === true"
 *     [variant]="someSignal() ? 'colored' : 'outlined'"
 *     (click)="someMethod()"
 * ></button>
 * ```
 */
@Component({
    selector: 'button[evc-icon-button], a[evc-icon-button]',
    template: `
        <span
            class="material-icon"
            [class.spin]="iconSpinOnLoading() && loading()"
        >{{icon()}}</span>
    `,
    styleUrl: './icon-button.component.scss',
    host: {
        '[class.sm]': 'btnSize() === "sm"',
        '[class.lg]': 'btnSize() === "lg"',
        '[class.loading]': 'loading()',
        '[class.pressed]': 'pressed()',
        '[class.toggled]': 'toggled()',
    },
})
export class IconButtonComponent {
    /** optional material icon, for example: "sync" */
    icon = input<string>();
    /** size of the button */
    btnSize = input<ButtonSize>("md");
    /** set true to enable a spinning animation for the icon if loading is true */
    iconSpinOnLoading = input<boolean>(false);
    /** set true to enable a loading animation (and disable the control) */
    loading = input<boolean>(false);
    /** use this flag to manually apply the pressed state appearance */
    pressed = input<boolean | null>(false);
    /** use this flag to apply a toggled state appearance (darker inset shadow) */
    toggled = input<boolean | null>(false);
}
