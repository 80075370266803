import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';

/**
 * Simple input component
 * 
 * Usage:
 * 
 * ```html
 * <input evc-input label="Name" placeholder="Enter your name" [(ngModel)]="name" />
 * <input
 *     evc-input
 *     placeholder="Enter your phone"
 *     type="tel"
 *     [disabled]="(condition$ | async) === true"
 *     [value]="someSignal()"
 *     (valueChange)="someChange($event)"
 * />
 * ```
 */
@Component({
    selector: 'input[evc-input]',
    template: '',
    styleUrl: './input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[style.--label-text]': 'label() ?? ""',
        '[class.labeled]': '!!label()'
    }
})
export class InputComponent {
    readonly label = input<string|undefined>(undefined);
}
