import {Component, input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: "td[copy-clipboard], div[copy-clipboard]",
    template: `
        <div class="copy-attribute-cont">
            <ng-content></ng-content>
            @if (payload()) {
                <button 
                    evc-icon-button
                    btnSize="sm"
                    class="copy-attribute"
                    icon="content_copy"
                    [copy-clipboard]="payload()"
                ></button>
            }
        </div>
    `,
    styleUrls: ['./copy-attribute.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CopyAttributeComponent {
    public payload = input<string | null>(null, {alias: "copy-clipboard"});
}
