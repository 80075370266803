@use "../variables";

/* 
 * USED FOR ICON-BUTTON LIKE COMPONENTS LIKE select-table-coumns dropdown 
 * Not appliccable to the "normal" button component!
 */
@mixin flat-button(
    $background-color: transparent,
    $background-hover-color: white,
    $background-toggled-color: #EFEFF0,
    $border-width: 1px,
    $border-radius: 5px,
    $text-color: variables.$text,
    $text-disabled-color: #C4C4C4,
) {
    --btn-bg-color: #{$background-color};
    background-color: var(--btn-bg-color);
    border: $border-width solid transparent;
    border-radius: $border-radius;
    color: $text-color;
    transition: all 0.3s ease-in-out;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 1px solid $primary;
    }

    &:hover:not(:active):not(.pressed):not(:disabled) {
        --btn-bg-color: #{$background-hover-color};
        box-shadow:
            0px 3px 1px 0px rgba(0, 0, 0, 0.04),
            0px 1px 8px 0px rgba(0, 0, 0, 0.16);
    }

    &:active:not(:disabled),
    &.pressed,
    &.toggled {
        background-image:
            linear-gradient(var(--btn-bg-color), var(--btn-bg-color)),
            $border;
        background-clip: padding-box, border-box;
        background-origin: border-box;
    }

    &:active,
    &.pressed {
        --btn-bg-color: #{$background-hover-color};
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.12) inset;
    }

    &.toggled {
        --btn-bg-color: #{$background-toggled-color};
        box-shadow:
            0px 2px 3px 0px rgba(0, 0, 0, 0.04) inset,
            0px 1px 6px 0px rgba(0, 0, 0, 0.14) inset;

        &:hover:not(:active):not(.pressed) {
            box-shadow:
                0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
                0px 1px 8px 0px rgba(0, 0, 0, 0.28) inset;
        }

        &:active,
        &.pressed {
            box-shadow:
                0px 2px 4px 0px rgba(0, 0, 0, 0.16) inset,
                0px 1px 8px 0px rgba(0, 0, 0, 0.56) inset;
        }
    }

    &:disabled {
        color: $text-disabled-color;
        cursor: not-allowed;
    }
}
