import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { appRepository } from 'src/app/core/stores/app.repository';
import { environment } from "src/environments/environment";

/** COMP STILL NEEDED? */
@Component({
    selector: 'app-admin-selection',
    template: `
        <div 
            class="background"
        >
            <div class="selection-field">
                <div class="selection-cont">
                    <div class="logo evailable"></div>
                    <div class="admin-title">Admin</div>
                    <button 
                        evc-button
                        btnSize="lg"
                        variant="colored"
                        icon="group"
                        class="w-100"
                        tabindex="1"
                        [title]="'NAVIGATION.MANAGE_USERS' | translate"
                        (click)="goToPage('/admin-users')"
                    >
                        {{ 'NAVIGATION.MANAGE_USERS' | translate }}
                    </button>
                    <button 
                        evc-button
                        btnSize="lg"
                        variant="colored"
                        icon="groups"
                        class="w-100"
                        tabindex="1"
                        [title]="'NAVIGATION.MANAGE_TENANT_ACCESS' | translate"
                        (click)="goToPage('/admin-tenants')"
                    >
                        {{ 'NAVIGATION.MANAGE_TENANT_ACCESS' | translate }}
                    </button>
                    <button 
                        evc-button
                        btnSize="lg"
                        variant="colored"
                        icon="chat"
                        class="w-100"
                        tabindex="1"
                        [title]="'NAVIGATION.NOTIFICATIONS' | translate"
                        (click)="goToPage('/admin-notifications')"
                    >
                        {{ 'NAVIGATION.NOTIFICATIONS' | translate }}
                    </button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./admin-selection.component.scss']
})
export class AdminSelectionComponent {

    readonly brandName: string = environment.brandName;

    constructor(
        public repo: appRepository,
        private _router: Router
    ) {}

    goToPage(path: string) {
        this._router.navigate([path])
    }
}
