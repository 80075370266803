import { ChangeDetectionStrategy, Component, effect, ElementRef, EventEmitter, input, Input, Output, Renderer2, ViewChild } from '@angular/core';

export type RadioButtonGroupOption<T> = {
    label: string | number;
    value: T;
    tooltip?: string;
}

@Component({
    selector: 'radio-button-group',
    template: `
        <div 
            class="button-group-wrapper"
            [class.line]="type() === 'line'"
            [class.loading]="loading()"
            #groupWrapper
        >
            @for (option of options(); track option.label) {
                <button
                    type="button"
                    [tooltip]="option.tooltip ? option.tooltip : null"
                    [size]="'small'"
                    [textAlign]="'left'"
                    [disabled]="loading()"
                    (click)="updateValue(option.value)"
                >
                    {{ option.label }}
                </button>
            }
            <span 
                class="active-state-slider"
                #activeStateIndicator
            ></span>
        </div>
    `,
    styleUrls: ['./radio-button-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonGroupComponent<T extends string | number | null> {
    @ViewChild('groupWrapper', {static: false}) groupWrapper: ElementRef | undefined;
    private _activeStateIndicator: ElementRef | undefined;
    @ViewChild('activeStateIndicator', {static: false}) set indicator(activeStateIndicator: ElementRef) {
        // move indicator once rendered in DOM, fallback to first option value if no activeSelection is set
        this._activeStateIndicator = activeStateIndicator;
        const options = this.options();
        const selection = this._activeSelection ?? (options && options[0] && !this.toggleNull() ? options[0].value : null);
        if (selection !== undefined) setTimeout(() => this.moveIndicator(selection), 1);
    };
    /** available options */
    public options = input<RadioButtonGroupOption<T>[] | null>(null);
    /** display either as button group or with a line below active options */
    public type = input<'buttons' | 'line'>('buttons');
    /** controls loading animation with disabled state */
    public loading = input<boolean>(false);
    /** enables toggle functionality, if true, activeSelection can be null */
    public toggleNull = input<boolean | null>(false);
    // internal active option
    private _activeSelection: T | null = null;
    /** active options value */
    @Input() set activeSelection(value: T) {
        if (!this.toggleNull() && value == null) return
        this._activeSelection = value;
        if (value !== undefined) this.moveIndicator(value);
    }
    @Output() activeSelectionChange = new EventEmitter<T | null>();

    constructor(
        private _renderer: Renderer2
    ) {
        // call moveIndicator method when initial options are updated
        // this prevents the layout from breaking e.g. when switching translations in runtime
        effect(() => {
            const options = this.options();
            if (!options || !this._activeSelection) return;
            this.moveIndicator(this._activeSelection)
        })
    }

    public updateValue(value: T | null) {
        if (!this.options || (!this.toggleNull() && this._activeSelection === value)) return;
        this._activeSelection = this.toggleNull() && this._activeSelection === value ? null : value;
        // emit value, set activeStateSlider in background of button active index
        this.moveIndicator(this._activeSelection);
        this.activeSelectionChange.emit(this._activeSelection);
    }

    /** moves and resizes indicator behind buttons */
    public moveIndicator(toValue: T | null) {
        const options = this.options();
        if (!options || !this.groupWrapper || !this._activeStateIndicator) return;
        if (this.toggleNull() && toValue == null) {
            this._setIndicatorStyles('opacity', '0');
            return;
        } else {
            this._setIndicatorStyles('opacity', '1');
        }
        // get index of button in group
        const index = options.map((option) => option.value).indexOf(toValue as T);
        // get reference element
        const activeButtonEl = this.groupWrapper.nativeElement.children[index];
        const activeButtonRect: DOMRect = activeButtonEl.getBoundingClientRect();
        const wrapperRect: DOMRect = this.groupWrapper.nativeElement.getBoundingClientRect();
        // set inidcator to dimensions of active button element
        this._setIndicatorStyles('width', activeButtonRect.width + 'px');
        this._setIndicatorStyles('left', activeButtonRect.left - wrapperRect.left + 'px');
    }

    private _setIndicatorStyles(style: string, value: any) {
        if (!this._activeStateIndicator) return;
        this._renderer.setStyle(this._activeStateIndicator.nativeElement, style, value);
    }
}
