import { Component, input } from '@angular/core';

export const BUTTON_VARIANTS = [
    "flat", // border hidden until hover/press
    "outlined", // visible grey border (default)
    "colored", // special emphasized purple border (rotating color gleam)
] as const;
export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];

export const BUTTON_SIZES = [
    "sm",
    "md",
    "lg",
] as const;
export type ButtonSize = (typeof BUTTON_SIZES)[number];

/**
 * Simple button component with optional icon.
 * Defaults to an outlined button (white bg and grey text without border).
 * 
 * Usage:
 * 
 * ```html
 * <button evc-button (click)="someMethod()">Click me</button>
 * <button 
 *     evc-button
 *     btnSize="lg"
 *     icon="sync"
 *     [iconSpinOnLoading]="true"
 *     [loading]="someSignal()"
 *     [disabled]="(condition$ | async) === true"
 *     [variant]="someSignal() ? 'colored' : 'outlined'"
 *     (click)="someMethod()"
 * >{{ 'SOME.BUTTON.LABEL' | translate }}</button>
 * ```
 * 
 * Adjust the overall appearance by modifying the following CSS variables:
 * 
 * ```scss
 * .my-button {
 *     --btn-bg-color: #{$scss-color-variable};
 *     --btn-default-border: #FFF;
 *     --btn-colored-border: rgba(69, 4, 20, .5);
 * }
 * ```
 * ```html
 * <button evc-button class="my-button">{{ 'SOME.BUTTON.LABEL' | translate }}</button>
 * ```
 * 
 */
@Component({
    selector: 'button[evc-button], a[evc-button]',
    template: `
        @if (icon()) {
            <span
                class="material-icon"
                [class.spin]="iconSpinOnLoading() && loading()"
            >{{icon()}}</span>
        }
        <span class="label"><ng-content></ng-content></span>
    `,
    styleUrl: './button.component.scss',
    host: {
        '[class.flat]': 'variant() === "flat"',
        '[class.colored]': 'variant() === "colored"',
        '[class.sm]': 'btnSize() === "sm"',
        '[class.lg]': 'btnSize() === "lg"',
        '[class.loading]': 'loading()',
        '[class.pressed]': 'pressed()',
        '[class.toggled]': 'toggled()',
        '[class.wrap-text]': 'wrapText()',
        '[class.reverse]': 'iconPosition() === "end"',
    },
})
export class ButtonComponent {
    /** optional material icon, for example: "sync" */
    icon = input<string>();
    /** position of icon in button */
    iconPosition = input<"start" | "end">("start");
    /**
     * Variant of the button:
     * - flat: border hidden until hover/press
     * - outlined: visible border (default)
     * - colored: special emphasized purple border (rotating color gleam)
     */
    variant = input<ButtonVariant>("outlined");
    /** size of the button */
    btnSize = input<ButtonSize>("md");
    /** set true to enable a spinning animation for the icon if loading is true */
    iconSpinOnLoading = input<boolean | null>(false);
    /** set true to enable a loading animation (and disable the control) */
    loading = input<boolean | null>(false);
    /** use this flag to manually apply the pressed state appearance */
    pressed = input<boolean | null>(false);
    /** use this flag to apply a toggled state appearance (darker inset shadow) */
    toggled = input<boolean | null>(false);
    /** set true to wrap text to multiple lines */
    wrapText = input<boolean | null>(false);
}
