import { ElementRef, Inject, Injectable, NgZone, Renderer2, RendererFactory2, inject } from '@angular/core';
import { EnumeratedState, StateColors, StateHelperService } from '../helpers/state-helper.service';
import { DOCUMENT, formatDate } from '@angular/common';
import { detailsRepository } from '../stores/details.repository';
import { NotificationService } from '../app-services';
import { addMinutes, closestIndexTo, isWithinInterval, subMilliseconds, subMinutes } from 'date-fns';
import { DisplayInfo, addGroup, dataMethods, defaultTooltipPositionFn, tooltipContent } from '../helpers/charts.helper'
import { Error } from '../data-backend/models';
import { EventCodeEvaluation, EventIconsService } from '../helpers/event-icons.service';
import { PermissionsService } from '../app-services/permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedStateNameService } from '../app-services/localized-state-name.service';
import { StateClassPipe } from '../pipes';

type CategoryData = { categoryTitle: string } | { categoryDimensionIndex: number }
type TimelineOptions = { initialZoomStart: number, initialZoomEnd: number }

type StateTooltipsIndices = {    
    timestampDimensionIndex: number,
    evaluationDimensionIndex: number
} & CategoryData


@Injectable({
    providedIn: 'root'
})
export class TimelineHelperService {
    private _stateHelper:   StateHelperService      = new StateHelperService();
    private _stateColors:   StateColors             = this._stateHelper.getStateColors();
    private _eventIconsService                      = inject(EventIconsService);
    private _ngZone:        NgZone                  = inject(NgZone);
    private _chartInstance: echarts.EChartsType | undefined;

    public detailsRepo: detailsRepository = inject(detailsRepository);
    public notificationService: NotificationService = inject(NotificationService);
    public permService: PermissionsService = inject(PermissionsService);
    public localizedStateName: LocalizedStateNameService = inject(LocalizedStateNameService);

    constructor(
        @Inject('dataSetIndex') private _eventsDatasetIndex?: number,
        @Inject('yAxisIndex') private _yAxisIndex?: number,
        @Inject('debug') private _debug?: boolean
    ) {}

    private _stateClassPipe: StateClassPipe = inject(StateClassPipe);
    // get renderer instance through factory
    private _rendererFactory = inject(RendererFactory2);
    private _renderer: Renderer2 = this._rendererFactory.createRenderer(null, null);
    private _document: Document = inject(DOCUMENT);
    private _translate: TranslateService = inject(TranslateService);

    // stores last known zoom level if chartInstance is set
    private _currentZoomLevels: {start: number, end: number} = {start: 0, end: 100};
    // stores last calculated tick interval to update on change only
    private _currentTickInterval: number = 0;
    // set new initial zoom levels
    set initialZoom(zoom: [number, number]) {
        this._setTicksOnXAxis(zoom[0], zoom[1]);
    }

    // updates tick interval on xAxis based on zoom levels
    private _setTicksOnXAxis = (start: number, end: number) => {
        this._ngZone.runOutsideAngular(() => {
            this._currentZoomLevels.start = start;
            this._currentZoomLevels.end = end;
            // Get xAxis object / array
            const chartOptions = this._chartInstance?.getOption();
            if (!chartOptions) {
                return;
            }
            const { xAxis } = chartOptions as any;
            if (!xAxis) {
                return;
            }

            // Get data range min and max
            var absoluteMin, absoluteMax;
            if (Array.isArray(xAxis)) {
                absoluteMin = xAxis[0].min;
                absoluteMax = xAxis[0].max;
            } else {
                absoluteMin = xAxis.min;
                absoluteMax = xAxis.max;
            }
            const newTickInterval: number = this.calcTickInterval(absoluteMin, absoluteMax);

            // Update tick interval only if new interval is different from previous
            if (newTickInterval != this._currentTickInterval) {
                this._currentTickInterval = newTickInterval;
                if (Array.isArray(xAxis)) {
                    // Update every entry in the array
                    xAxis.forEach((axis) => {
                        this._chartInstance?.setOption({
                            xAxis: [{
                                id: axis.id,
                                minInterval: newTickInterval,
                                interval: newTickInterval,
                                // @ts-ignore
                                maxInterval: newTickInterval,
                            }]
                        });
                    });
                } else {
                    // Update only the root object
                    this._chartInstance?.setOption({
                        xAxis: {
                            minInterval: newTickInterval,
                            interval: newTickInterval,
                            // @ts-ignore
                            maxInterval: newTickInterval,
                        }
                    });
                }
            }
        });
    }

    // init service with chart instance
    // this cant be injected in the constructor as it would cause a circular dependency injection
    // chartInstance is only required if the component wants to access the events- or tooltip renderer
    public init(instance: echarts.EChartsType, timelineOptions: TimelineOptions) {
        this._chartInstance = instance;
        this._currentZoomLevels.start = timelineOptions.initialZoomStart;
        this._currentZoomLevels.end = timelineOptions.initialZoomEnd;

        // listen to dataZoom events on chart instance to keep track of event groups based on different zoom levels
        this._ngZone.runOutsideAngular(() => {
            this._chartInstance?.on('datazoom', (event: any) => {
                const start = event.start ?? event.batch[0].start;
                const end = event.end ?? event.batch[0].end;
                this._setTicksOnXAxis(start, end);
            })
        })
    }

    private _t = (path: string, interpolateParams?: Object): string => {
        return this._translate.instant(path, interpolateParams)
    }

    // == K E E P   T O O L T I P S   O N   C L I C K == //

    private _unlisteners: (() => void)[] = [];
    // handles resetting interaction trigger for tooltips on chart instance, removes listeners
    private _resetTooltipTrigger() {
        this._chartInstance?.setOption({
            tooltip: { triggerOn: 'mousemove|click' }
        });
        this._chartInstance?.dispatchAction({type: 'hideTip'});

        this._unlisteners.forEach((unlistenFn) => unlistenFn())
        this._chartInstance?.off('globalout', () => this._resetTooltipTrigger())
    }
    // sets listeners to tooltip, echart instance and chart wrapper
    private _setTooltipListeners(tooltipIDClass: string, chartWrapper: ElementRef | undefined) {
        const activeTooltip = this._document.body.querySelector(`.echarts-event-tooltip.${tooltipIDClass}`);

        // reset if no active tooltip or tooltip is not visible
        if (!activeTooltip || (activeTooltip as HTMLElement).style.display !== 'block') {
            this._resetTooltipTrigger()
        }

        this._chartInstance?.on('globalout', () => this._resetTooltipTrigger())
        this._unlisteners.push(
            this._renderer.listen(chartWrapper?.nativeElement, 'mouseup', () => this._resetTooltipTrigger()),
            this._renderer.listen(activeTooltip, 'mouseleave', () => this._resetTooltipTrigger())
        )
    }
    // callable with ID class of HTML Tooltip and elRef of chart wrapper
    public keepTooltip(tooltipIDClass: string, chartWrapper: ElementRef | undefined) {
        this._ngZone.runOutsideAngular(() => {
            if (!this._chartInstance) return

            // toggle tooltip trigger to either only click (if new tooltip), or mousemove|click to access all tooltips again
            this._ngZone.runOutsideAngular(() => {
                this._chartInstance?.setOption({
                    tooltip: {
                        triggerOn: 'click'
                    }
                })
            })
        });
        this._setTooltipListeners(tooltipIDClass, chartWrapper)
    }

    // returns formatted string if possible. Returns '-' if input is undefined or null. returns stringified input as fallback
    private _tryDate = (input: string | undefined, format: string): string => {
        if (!input) return ' - '
        try {
            const timestamp = new Date(input);
            return formatDate(timestamp, format, this._translate.currentLang)
        } catch {
            return input.toString()
        }
    }

    // returns visual map along xAxis, based on given ranges
    // provides two default ranges sets, one for EnumeratedStates (evaluations), one for percentages (e.g. for HI Values)
    // ranges can either contain min/max values for continuous data or a single value to compare against (categories)
    public visualMapper(
        data: any[],
        valueKey: string | number,       // key/pos of value in data[] to compare ranges against
        timestampKey: string | number,   // key/pos of value plotted on xAxis
        ranges: 'evaluations' | 'percentages' | {
            id: string,
            color: string,
            value?: string,
            min?: number,
            max?: number,
            values?: any[]
        }[]
    ) {
        const evaluationRanges: {
            id: string,
            color: string,
            value: EnumeratedState | string
        }[] = [
            {
                id: 'ok',
                color: this._stateColors.ok,
                value: 'Ok'
            },
            {
                id: 'toBeMonitored',
                color: this._stateColors.toBeMonitored,
                value: 'To Be Monitored'
            },
            {
                id: 'potentialFailure',
                color: this._stateColors.potentialFailure,
                value: 'Potential Failure'
            },
            {
                id: 'failure',
                color: this._stateColors.failure,
                value: 'Failure'
            },
            {
                id: 'noData',
                color: this._stateColors.noData,
                value: 'No Data'
            },
            {
                // e.g., might be used in charging model state
                id: 'stillCollectingData',
                color: this._stateColors.noData,
                value: 'Still Collecting Data'
            }
        ];

        const percentageRanges: {
            id: string,
            color: string,
            min?: number,
            max?: number,
            value?: number | null
        }[] = [
            {
                id: 'ok',
                color: this._stateColors.ok,
                min: 75,
                max: 100
            },
            {
                id: 'toBeMonitored',
                color: this._stateColors.toBeMonitored,
                min: 50,
                max: 74
            },
            {
                id: 'failure',
                color: this._stateColors.failure,
                min: 0,
                max: 49
            },
            {
                id: 'noData',
                color: this._stateColors.noData,
                value: null
            }
        ]

        const useRanges: {
            id: string,
            color: string,
            value?: string | number | null,
            min?: number,
            max?: number,
            values?: any[]
        }[] = ranges === 'evaluations' 
            ? evaluationRanges
            : ranges === 'percentages'
                ? percentageRanges
                : ranges;
        // add empty values array to each range
        useRanges.map((range) => {
            range['values'] = []
            return range
        })

        // returns true if dataPoint is either in between min/max or equals value
        const isMatching = (dataPoint: any, range: typeof useRanges[0]): boolean => {
            const useMinMax = range.min !== undefined && range.max !== undefined;
            const value = dataPoint[valueKey];
            if (useMinMax) return typeof value == 'number' && value >= range.min! && value <= range.max!;
            return value === range.value
        }

        // returns array of segments
        const spliceAtNull = (values: any[]): string[][] => {
            let sections: string[][] = [];
        
            const splicer = (values: any[]) => {
                while (values.length > 0) {
                    const nullIndex = values.indexOf(null);
                    if (nullIndex === -1) {
                        sections.push(values);
                        break;
                    }
                    let section = values.slice(0, nullIndex);
                    sections.push(section);

                    // remove all nulls at start of array
                    values = values.slice(nullIndex + 1);
                }
            }
        
            splicer(values);
        
            return sections;
        }

        data.forEach((dataPoint, dataIndex) => {
            let nextValue = data[dataIndex + 1];
            useRanges.forEach((range) => {
                if (isMatching(dataPoint, range)) {
                    // add to range src if matching
                    range.values!.push(dataPoint[timestampKey])

                    // test if next value would match
                    // if not, we need to add the following point as "end" of this section and close gaps
                    if (nextValue && !isMatching(nextValue, range)) {
                        // set the visualmap piece 1 millisecond before the real next timestamp to reduce overlaps
                        const nextDate = new Date(nextValue[timestampKey]);
                        const rightBeforeNext = subMilliseconds(nextDate, 1);
                        range.values!.push(rightBeforeNext)
                    }
                } else {
                    // null pos of non-matching sections
                    range.values!.push(null)
                }
            })
        })

        // remove all nulls in each section, set min/max along xAxis for each section, set range color
        return useRanges.flatMap((range) => {
            let sections = spliceAtNull(range.values!).filter(section => section.length > 0);
            return sections.map(section => {
                return {
                    min: new Date(section[0]).getTime(),
                    max: new Date(section[section.length - 1]).getTime(),
                    color: range.color
                }
            })
        })
    }

    // == E V E N T S == //

    // closure for events tooltips renderer
    // has access of grouped events created by renderer function in this helper instance, thus can return aggregated tooltips
    public eventsTooltipsFactory(options?: {
        showUseForDefectBtn: boolean 
    }): {
        formatter: (params: any, ticket: string) => any,
        borderWidth: number,
        borderColor: string,
        padding: number,
        position: (point: any[], params: Object | Array<Object>, dom: HTMLElement, rect: DOMRect, size: Object) => any
    } | undefined {
        return this._ngZone.runOutsideAngular(() => {
            if (!this._chartInstance) throw "Cannot access echart instance"
            if (this._eventsDatasetIndex == undefined) throw "eventsTooltipsFactory requires dataSetIndex"

            // handling provided options
            const showUseForDefectBtn = options !== undefined && options.showUseForDefectBtn;
            // get helper methods for indexes of dimensions
            const { _getBulkDimensionIndexes } = dataMethods(this._chartInstance, this._eventsDatasetIndex);
            const allIndexes = _getBulkDimensionIndexes(['id', 'icon', 'evaluation', 'color', 'eventType', 'errorCode', 'status', 'errorDescription', 'info', 'timestamp', 'vendorErrorCode', 'vendorId']);

            // return if any of the requested indexes are undefined
            if (Object.values(allIndexes).includes(undefined)) return

            // write index of each dimension to const
            const {
                idDimensionIndex,
                iconDimensionIndex,
                evaluationDimensionIndex,
                colorDimensionIndex,
                eventTypeDimensionIndex,
                errorCodeDimensionIndex,
                statusDimensionIndex,
                errorDescriptionDimensionIndex,
                infoDimensionIndex,
                timestampDimensionIndex,
                vendorErrorCodeDimensionIndex,
                vendorIdDimensionIndex
            } = allIndexes as {[name: string]: number}
            //             ^ as we checked before if any indexes are undefined, we can tell TS we only handle numbers from here on

            // only appliccable for events of "station-timeline-plot"
            const extraDimensions = _getBulkDimensionIndexes(['extra_info_1', 'extra_info_2', 'extra_info_3']);
            const {
                extra_info_1DimensionIndex,
                extra_info_2DimensionIndex,
                extra_info_3DimensionIndex
            } = extraDimensions as {[name: string]: number}

            // Helper for hover formatter, takes single point, returns all events in same group
            const _getEventsOfGroup = (params: any) => {
                const eventId = params['data'][idDimensionIndex];
                return this._groups?.groups.find((group) => {
                    return group.map((entry) => entry[idDimensionIndex]).includes(eventId)
                })            
            }

            // groups events into all available evaluations
            const _groupEventsByEvaluation = (events: any[]): {
                [name: string]: {
                    color: string,
                    events: any[]
                }
            } => {
                // get all evaluations in current group, group entries by evaluation
                let evaluations: {
                    [name: string]: {
                        color: string,
                        events: any[]
                    }
                } = {};

                events.forEach((event) => {
                    const eventEvaluation = event[evaluationDimensionIndex];
                    const evaluationGroup = evaluations[eventEvaluation];

                    if (evaluationGroup) {
                        evaluationGroup.events.push(event)
                    } else {
                        evaluations[eventEvaluation] = {
                            color: event[colorDimensionIndex],
                            events: [
                                event
                            ]
                        }
                    }
                });

                // sort evaluations worst -> best, no data
                const keys = Object.keys(evaluations);
                keys.sort((a, b) => this._stateHelper.sortByState(a as EnumeratedState, b as EnumeratedState));
                let sortedEvaluations: {
                    [name: string]: {
                        color: string,
                        events: any[]
                    }
                } = {};
                // build new obj based on sorted keys
                keys.forEach((key) => {
                    sortedEvaluations[key] = evaluations[key]
                })

                return sortedEvaluations
            }

            const formatterFn = (params: any, ticket: string) => {
                // get all events in same aggregation group
                const aggregatedEvents = _getEventsOfGroup(params);
                if (!aggregatedEvents) return;

                // get index in group
                const highlightedEventIndex = aggregatedEvents.indexOf(this.highlightedEvent);
                const noHighlightedEvent = !this.highlightedEvent || highlightedEventIndex == -1;
                const highlightedEvaluation = this.highlightedEvent && highlightedEventIndex != -1 ? this.highlightedEvent[evaluationDimensionIndex] : null;

                let headerContent: string = '';
                let eventsContent: string = '';
                
                const evaluations = aggregatedEvents.length > 1 ? _groupEventsByEvaluation(aggregatedEvents) : {};
                // add filter buttons for evaluations if at least 2 eval groups are available
                if (Object.keys(evaluations).length > 1) {
                    // TODO: if highlighted event in group, set event evaluation to active
                    let filterButtons = `<button class="chart-filter-button ${noHighlightedEvent ? 'active' : ''}" data-filter-evaluation="reset-all">All</button>`;
                    Object.keys(evaluations).forEach((evaluationKey) => {
                        const evaluation = evaluations[evaluationKey];
                        filterButtons += `
                        <button 
                            class="chart-filter-button ${highlightedEvaluation == evaluationKey ? 'active' : ''}"
                            title="${evaluationKey}"
                            data-filter-evaluation="${evaluationKey}"
                        >
                            <span
                                class="filter-dot"
                                style="background-color: ${evaluation.color};"
                            ></span>
                            <span>${evaluation.events.length}</span>
                        </button>`
                    });

                    headerContent += `<div class="filter-buttons-container">${ filterButtons }</div>`;
                }

                let icons = '';
                aggregatedEvents.forEach((event, index) => {
                    const eventType = event[eventTypeDimensionIndex];

                    let displayInfo = [];
                    
                    if (eventType == 'authorization') {
                        // display info for "authorization"
                        displayInfo = [
                            {
                                headline: event[infoDimensionIndex],
                                info: this._t('DETAILS_VIEW.TIMELINE.AUTHORIZATION'),
                                show: true
                            },
                            {
                                pre: this._t('DETAILS_VIEW.TIMELINE.ID_TAG'),
                                main: event[extra_info_2DimensionIndex],
                                show: (() => {
                                    return event[extra_info_2DimensionIndex] !== undefined && event[extra_info_2DimensionIndex] !== null
                                })()
                            },
                            {
                                pre: this._t('DETAILS_VIEW.TIMELINE.PARENT_ID_TAG'),
                                main: event[extra_info_3DimensionIndex],
                                show: (() => {
                                    return event[extra_info_3DimensionIndex] !== undefined && event[extra_info_3DimensionIndex] !== null
                                })()
                            },
                            {
                                sectionClass: 'col',
                                pre: this._t('DETAILS_VIEW.TIMELINE.REQUESTED'),
                                main: (() => {
                                    return this._tryDate(event[timestampDimensionIndex], 'HH:mm:ss.SS')
                                })(),
                                sub: (() => {
                                    return this._tryDate(event[timestampDimensionIndex], 'MMM d y')
                                })(),
                                show: true
                            },
                            {
                                sectionClass: 'col',
                                pre: this._t('DETAILS_VIEW.TIMELINE.EXPIRES'),
                                main: (() => {
                                    return this._tryDate(event[extra_info_1DimensionIndex], 'HH:mm:ss.SS')
                                })(),
                                sub: (() => {
                                    return this._tryDate(event[extra_info_1DimensionIndex], 'MMM d y')
                                })(),
                                show: true
                            }
                        ]
                    } else if (eventType == 'connectionLost') {
                        const roundedPercentage = (input: number): number => parseFloat(input.toFixed(2))

                        displayInfo = [
                            {
                                headline: this._t(event[infoDimensionIndex]),
                                show: true
                            }, 
                            {
                                pre: this._t('COMMON.TIMESTAMP.ONE'),
                                main: (() => {
                                    return this._tryDate(event[timestampDimensionIndex], 'HH:mm:ss.SS')
                                })(),
                                sub: (() => {
                                    return this._tryDate(event[timestampDimensionIndex], 'MMM d y')
                                })(),
                                show: true
                            },
                            {
                                sectionClass: 'col',
                                show: true,
                                pre: this._t('DETAILS_VIEW.TIMELINE.FROM'),
                                main: roundedPercentage(event[extra_info_1DimensionIndex]) + '%'
                            },
                            {
                                sectionClass: 'col',
                                show: true,
                                pre: this._t('DETAILS_VIEW.TIMELINE.TO'),
                                main: roundedPercentage(event[extra_info_2DimensionIndex]) + '%'
                            }
                        ]
                    } else if (eventType == 'restart') {
                        displayInfo = [
                            {
                                headline: event[errorDescriptionDimensionIndex] ?? event[infoDimensionIndex] ?? '-',
                                info: this._t('DETAILS_VIEW.TIMELINE.RESTART'),
                                show: true
                            },
                            {
                                pre: this._t('DETAILS_VIEW.TIMELINE.CATEGORY'),
                                main: event[extra_info_2DimensionIndex],
                                show: true
                            },
                            {
                                pre: this._t('COMMON.TIMESTAMP.ONE'),
                                main: (() => {
                                    return this._tryDate(event[timestampDimensionIndex], 'HH:mm:ss.SS')
                                })(),
                                sub: (() => {
                                    return this._tryDate(event[timestampDimensionIndex], 'MMM d y')
                                })(),
                                show: true
                            },
                            {
                                sectionClass: 'col',
                                show: true,
                                pre: this._t('DETAILS_VIEW.TIMELINE.SUCCESS'),
                                main: event[extra_info_1DimensionIndex] ?? '-',
                            },
                            {
                                sectionClass: 'col',
                                show: true,
                                pre: this._t('DETAILS_VIEW.TIMELINE.NUM_OF_BOOT_NOTIFICATIONS'),
                                main: event[extra_info_3DimensionIndex]
                            }
                        ];
                    } else {
                        // display info for eventTypes "status" and "errors"
                        displayInfo = [
                            // headline for status notifications
                            {
                                headline: event[statusDimensionIndex],
                                info: event[errorCodeDimensionIndex] ?? '-',
                                show: (() => {
                                    const status = event[statusDimensionIndex]
                                    return eventType == 'status' && status !== null  && status !== undefined
                                })()
                            },
                            // headline for errors 
                            {
                                headline: event[errorCodeDimensionIndex],
                                info: this.localizedStateName.instant(event[statusDimensionIndex]),
                                show: (() => {
                                    const errorCode = event[errorCodeDimensionIndex]
                                    const status = event[statusDimensionIndex]
                                    return eventType == 'errors' && errorCode !== null && errorCode !== undefined && status !== null  && status !== undefined
                                })()
                            },
                            {
                                pre: this._t('DETAILS_VIEW.TIMELINE.INFO'),
                                main: event[infoDimensionIndex] || '-',
                                show: true
                            },
                            {
                                pre: this._t('DETAILS_VIEW.TIMELINE.DESCRIPTION'),
                                main: event[errorDescriptionDimensionIndex] || '-',
                                show: (() => event[errorDescriptionDimensionIndex])()
                            },
                            {
                                pre: this._t('COMMON.TIMESTAMP.ONE'),
                                main: (() => {
                                    return this._tryDate(event[timestampDimensionIndex], 'HH:mm:ss.SS')
                                })(),
                                sub: (() => {
                                    return this._tryDate(event[timestampDimensionIndex], 'MMM d y')
                                })(),
                                show: true
                            },
                            {
                                pre: this._t('DETAILS_VIEW.TIMELINE.VENDOR'),
                                main: (() => {
                                    const errorCode = event[vendorErrorCodeDimensionIndex];
                                    return `${this._t('DETAILS_VIEW.TIMELINE.ERROR_CODE')}: ${errorCode !== undefined && errorCode !== null ? errorCode : '-'}`
                                })(),
                                sub: (() => {
                                    const vendorId = event[vendorIdDimensionIndex];
                                    return `ID: ${vendorId !== undefined && vendorId !== null ? vendorId : '-'}`
                                })(),
                                show: (() => {
                                    const vendorId = event[vendorIdDimensionIndex];
                                    const errorCode = event[vendorErrorCodeDimensionIndex];
                                    return vendorId !== null && vendorId !== undefined && vendorId.length > 0 && errorCode !== null && errorCode !== undefined && errorCode.length > 0
                                })()
                            },
                            {
                                useForDefect: true,
                                info: this._translate.instant('DETAILS_VIEW.USE_FOR_DEFECT'),
                                main: event[errorCodeDimensionIndex],
                                sub: event[timestampDimensionIndex],
                                show: showUseForDefectBtn
                            },
                        ];
                    }

                    const eventContent = tooltipContent(displayInfo);
                    // highlighted if event is highlightedEvent or first in loop
                    const isHighlighted = highlightedEventIndex > -1 ? highlightedEventIndex === index : index === 0;

                    eventsContent += `
                        <div 
                            style="${isHighlighted ? '' : 'display: none'}" 
                            data-content-id="${index}"
                            class="sections-wrapper"
                        >
                            ${eventContent}
                        </div>
                    `;

                    // escape json string for html template
                    const escapeHtml = (input: string): string => {
                        return input.replace(/&/g, "&amp;")
                            .replace(/</g, "&lt;")
                            .replace(/>/g, "&gt;")
                            .replace(/"/g, "&quot;")
                            .replace(/'/g, "&#039;");
                    }

                    // add event icon
                    icons += `
                        <button 
                            class="chart-event-button ${ isHighlighted ? 'active' : '' }"
                            style="background-color: ${event[colorDimensionIndex]};"
                            data-event-id="${index}"
                            data-event-evaluation="${ event[evaluationDimensionIndex] }"
                            data-event-data="${escapeHtml(JSON.stringify(event))}"
                        >${event[iconDimensionIndex]}</button>
                    `;
                })
                // add event selection icons
                if (aggregatedEvents.length > 1) {
                    headerContent += `<div class="scroll-overflow-container scroll-padding">${ icons }</div>`
                }

                return headerContent + eventsContent
            }

            const positionFn = (point: any[], params: Object | Array<Object>, dom: HTMLElement, rect: DOMRect, size: any) => {
                // get all events in same aggregation group
                const aggregatedEvents = _getEventsOfGroup(params);
                const evaluations = aggregatedEvents ? _groupEventsByEvaluation(aggregatedEvents) : {};
                // if tooltip features aggregated events, it's positioned slightly higher to account for select options
                let posTop = rect.y - 20;
                // if (aggregatedEvents && aggregatedEvents.length > 1) posTop -= 10;
                if (Object.keys(evaluations).length > 1) posTop -= 31;

                // align left / right of point based on overflow
                const viewWidth = size['viewSize'][0];
                const tooltipWidth = size['contentSize'][0];
                let posLeft = rect.x + 42;
                posLeft = posLeft + tooltipWidth > viewWidth - 30 ? rect.x - tooltipWidth - 10 : posLeft;

                return [posLeft, posTop]
            }

            return {
                formatter: formatterFn,
                borderWidth: 0.5,
                borderColor: '#C4C4C4',
                padding: 12,
                position: positionFn
            }
        })
    }

    // prepares provided data, then updates plot with base dataSet
    public prepareEventsRendererData(dataSets: ({
        id: string;
        dimensions: string[];
        source: any[];
    } | {
        id: string;
        transform: any;
        dimensions?: undefined;
        source?: undefined;
    })[]){
        this._ngZone.runOutsideAngular(() => {
            if (!this._chartInstance) throw "Cannot access echart instance"
            if (this._eventsDatasetIndex == undefined) throw "prepareEventsRendererData requires dataSetIndex"
            if (this._yAxisIndex == undefined) throw "prepareEventsRendererData requires yAxisIndex"

            // prepare data
            const chartOptions = this._chartInstance.getOption();
            const eventDataset = dataSets[0];
            // this._dataSetIndex refers to the index of event source
            // using a structuredClone to make sure we don't manipulate the original source array
            const data = structuredClone(eventDataset.source);
            if (!eventDataset || !data) return
            // remove first entry which only features the dimensions
            data.splice(0, 1)
            const categoryDimensionIndex = eventDataset.dimensions!.indexOf('category');

            // get yAxis to plot against
            let yAxis: any = chartOptions['yAxis'];
            yAxis = yAxis instanceof Array ? yAxis[this._yAxisIndex] : yAxis;
            const categoriesOnY = yAxis?.['data'] || [];
            
            let dataByCategory: any = {};

            categoriesOnY.forEach((category: string, index: number) => {
                dataByCategory[category] = data.filter((entry: any[]) => entry[categoryDimensionIndex] == category)
            })

            this._eventData = data;
            this._eventDataByCategories = dataByCategory;

            // finally set original dataSets, causing echarts renderer to update
            this._chartInstance.setOption({
                dataset: dataSets
            })

            if (this._debug) {
                console.log('[DEBUG TimelineHelperService] prepareEventsRendererData', {
                    eventData: this._eventData,
                    eventDataByCategory: this._eventDataByCategories
                })
            }
        })
    }
    
    private _eventData: any[] = [];
    private _eventDataByCategories: {
        [category: string]: []
    } = {};

    // stores ref of all aggregated events and sessions of current zoom
    private _groups: {
        zoom: {
            start: number,
            end: number
        },
        groups: [any[]]
    } | undefined;
    
    // closure for events renderer
    // takes current chart instance, index of data in dataSet and configuration options
    public eventsRendererFactory(
        config: {
            iconSize: number,
            aggregationThreshold: number
        }
    ): ((params: any, api: any) => any) | undefined
     {
        return this._ngZone.runOutsideAngular(() => {
            if (!this._chartInstance) throw "Cannot access echart instance"

            const { _getBulkDimensionIndexes } = dataMethods(this._chartInstance, this._eventsDatasetIndex!);
            const allIndexes = _getBulkDimensionIndexes(['timestamp', 'icon', 'evaluation', 'category', 'id'])
            if (Object.values(allIndexes).includes(undefined)) return

            const {
                timestampDimensionIndex,
                iconDimensionIndex,
                evaluationDimensionIndex,
                categoryDimensionIndex,
                idDimensionIndex
            } = allIndexes as {[name: string]: number}

            // get yAxis to plot against
            let yAxis: any = this._chartInstance.getOption()['yAxis'];
            if (yAxis instanceof Array) {
                yAxis = yAxis[this._yAxisIndex!]
            }
            const categoriesOnY = yAxis?.['data'] || [];

            // returns previous event of same category or undefined
            const _getPreviousEventByCategoryAndIndex = (categoryIndex: number, idInDataSet: number): any[] | undefined => {
                // get prepared data
                const category = categoriesOnY[categoryIndex];
                if (!category) return
                // find corresponding data to this category
                const categoryData = this._eventDataByCategories[category];
                if (!categoryData) return
                // get index of this entry in this category
                let indexInCategory;
                for (let i = 0; i < categoryData.length; i++) {
                    if (categoryData[i][idDimensionIndex] === idInDataSet) {
                        indexInCategory = i;
                        break;
                    }
                }

                if (indexInCategory == undefined || indexInCategory == 0) return

                // return previous entry based on index in group
                return categoryData[indexInCategory - 1]
            }

            // returns all events in current zoom from dataSet[dataIndex], going forward, starting from "startIndex"
            // takes startIndex of current point and count of following points to check for aggregation
            const _getEventsByCategoryAndIndex = (categoryIndex: number, startIndex: number, numOfPoints: number): any[] => {
                const category = categoriesOnY[categoryIndex];
                const endIndex = startIndex + numOfPoints;
                const res = [];

                // iterate over all events that are currently visible in the chart
                for (let i = startIndex; i < endIndex && i < this._eventData.length; i++) {
                    const event = this._eventData[i];
                    // filter results by category dimension
                    if (event && event[categoryDimensionIndex] === category) {
                        res.push(event);
                    }
                }

                return res
            }

            const halfIconSize = config.iconSize * 0.5;
            const $this = this;

            return function (params: any, api: any) {
                let iconX = api.value('timestamp'),
                    category = api.value('category'),
                    iconPos = api.coord([iconX, category]);

                // if first message, no previous can be overlapped
                if (params.dataIndexInside !== 0) {
                    // check if previous message would overlap with this one. If so, do not plot this message, as it's already included in previous group
                    const previousOcpp = _getPreviousEventByCategoryAndIndex(category, api.value('id'));
                    if (previousOcpp) {
                        // get X position of previousOcpp, check overlap with current point pos
                        const previousOcppPos = api.coord([
                            previousOcpp[timestampDimensionIndex],
                            category
                        ]);

                        // if right corner of prev (+ threshold) overlaps left corner of current
                        if (previousOcppPos[0] + halfIconSize + config.aggregationThreshold > iconPos[0] - halfIconSize) {
                            // return out of renderer, as we dont need to plot anything for this point (yet)
                            return
                        }
                    }
                }

                // get count of following ocpps by subtracting current InsideIndex from InsideLength in current zoom
                // both params with "inside" prepended refer to index/length of only plotted points
                const numOfFollowingOcpps = params.dataInsideLength - params.dataIndexInside;
                const followingOcpps = _getEventsByCategoryAndIndex(category, params.dataIndex, numOfFollowingOcpps)
                // collect all ocpp messages in this group that overlap each other
                let ocppsInGroup: any[] = [];

                // index 0 = current point the renderer is called on
                let i = 0;
                while (i < followingOcpps.length) {
                    let thisOcpp = followingOcpps[i];
                    ocppsInGroup.push(thisOcpp);

                    if (i === followingOcpps.length - 1) {
                        break;
                    }

                    // we need to calculate for each point if current ocpp will be overlapped by the following
                    let nextOcpp = followingOcpps[i + 1],
                        thisOcppPos = api.coord([thisOcpp[timestampDimensionIndex], category]),
                        nextOcppPos = api.coord([nextOcpp[timestampDimensionIndex], category]);

                    // if next is not overlapping current, break
                    if (!(thisOcppPos[0] + halfIconSize + config.aggregationThreshold > nextOcppPos[0] - halfIconSize)) {
                        break;
                    }

                    i++;
                }

                // get position of last ocpp in group to calculate total group width
                // if group contains no other icons, default back to current notification icon pos
                let lastOcppPos = ocppsInGroup.length > 0
                    ? api.coord([
                        ocppsInGroup[ocppsInGroup.length - 1][timestampDimensionIndex],
                        category
                    ])
                    : iconPos;

                $this._groups = addGroup($this._groups, $this._currentZoomLevels, ocppsInGroup, idDimensionIndex)

                if ($this._debug) {
                    console.log('[DEBUG TimelineHelperService] eventRenderer', {
                        thisParams: params,
                        followingOcpps: followingOcpps,
                        lastOcppPos: lastOcppPos,
                        groups: $this._groups
                    })
                }

                // define group icon and aggregation marker style
                // if all ocpps in group have the same icon, keep the icon and set total num in top right corner ('same')
                // else only show counter ('mixed')
                const allOcppIcons: string[] = [...ocppsInGroup.map(ocpp => ocpp[iconDimensionIndex]), api.value('icon')];
                const uniqueIcons = [...new Set(allOcppIcons)];
                /** true if all events in group are of the same type */
                const isSameGroupMarkerStyle = uniqueIcons.length === 1;
                // get worst state and color group accordingly
                const allOcppEvaluations: EnumeratedState[] = [...ocppsInGroup.map(ocpp => ocpp[evaluationDimensionIndex]), api.value('evaluation')];
                const lowestState = $this._stateHelper.getLowestEnumeratedStateInArray(allOcppEvaluations)[0];
                const groupColorIndex = $this._eventIconsService.eventCodeEvaluation[0].indexOf(lowestState as EventCodeEvaluation[0][number])
                const groupColor = $this._eventIconsService.eventCodeEvaluation[1][groupColorIndex];

                // create group
                let groupShape = {
                    x: iconPos[0],
                    y: iconPos[1] - 12,
                    width: lastOcppPos[0] - iconPos[0],
                    height: 30
                };

                // 24 if icon or two-digit counter, else (counter = 99+) = 32
                let iconWidth = isSameGroupMarkerStyle ? config.iconSize : ocppsInGroup.length > 99 ? config.iconSize * 1.5 : config.iconSize;

                let groupRect: any = groupShape && {
                    type: 'group',
                    x: groupShape.x,
                    y: groupShape.y,
                    children: [
                        // dashed line in background spanning over group
                        {
                            type: 'line',
                            transition: ['shape'],
                            silent: true,
                            shape: {
                                x1: 0,
                                x2: groupShape.width,
                                y1: 12,
                                y2: 12,
                            },
                            style: {
                                stroke: groupColor,
                                fill: 'transparent',
                                lineWidth: 2,
                                lineDash: [4]
                            }
                        },
                        // vertical lines at the bounds of the group
                        {
                            type: 'line',
                            transition: ['shape'],
                            silent: true,
                            shape: {
                                x1: 0,
                                x2: 0,
                                y1: 7,
                                y2: 17
                            },
                            style: {
                                stroke: groupColor,
                                lineWidth: 2
                            }
                        },
                        {
                            type: 'line',
                            transition: ['shape'],
                            silent: true,
                            shape: {
                                x1: groupShape.width,
                                x2: groupShape.width,
                                y1: 7,
                                y2: 17
                            },
                            style: {
                                stroke: groupColor,
                                lineWidth: 2
                            }
                        },
                        // group icon
                        {
                            type: 'rect',
                            transition: ['shape'],
                            z: 4,
                            shape: {
                                x: groupShape.width * 0.5 - (iconWidth * 0.5), // place centered on timestamp
                                y: 0, // - half icon size to place in center of lane
                                width: iconWidth,
                                height: 24,
                                r: 6
                            },
                            style: {
                                // show icon if all in group are the same, else show count of mixed events of category
                                text: isSameGroupMarkerStyle ? uniqueIcons[0] : ocppsInGroup.length > 99 ? '99+' : ocppsInGroup.length,
                                fill: groupColor,
                                fontFamily: isSameGroupMarkerStyle ? 'Material Icons' : '"ChesnaGrotesk", Arial, Geneva, Helvetica, sans-serif',
                                textFill: 'white',
                                fontSize: isSameGroupMarkerStyle ? 17 : 14,
                                textOffset: [0, isSameGroupMarkerStyle ? 0 : 1],
                                shadowBlur: 2,
                                shadowColor: 'rgba(0, 0, 0, 0.15)',
                                shadowOffsetX: 2,
                                shadowOffsetY: 2
                            }
                        }
                    ]
                }

                // append count of ocpp in group if all are the same symbol
                if (isSameGroupMarkerStyle && ocppsInGroup.length > 1) {
                    let shapeWidth = ocppsInGroup.length > 9 ? ocppsInGroup.length > 99 ? 30 : 22 : 16;

                    groupRect && groupRect.children.push({
                        type: 'rect',
                        transition: ['shape'],
                        z: 5,
                        silent: true,
                        shape: {
                            x: groupShape.width * 0.5 + 4,
                            y: -6,
                            width: shapeWidth,
                            height: 16,
                            r: 20
                        },
                        style: {
                            fill: '#C4C4C4',
                            text: ocppsInGroup.length > 99 ? '99+' : ocppsInGroup.length,
                            textFill: 'white',
                            fontSize: 12,
                            textOffset: [0, .5],
                            shadowBlur: 4,
                            shadowColor: 'rgba(0, 0, 0, 0.25)',
                            shadowOffsetX: 4,
                            shadowOffsetY: 4
                        }
                    })
                }

                return groupRect
            }
        })
    }

    // last event that was highlighted using the openTooltip fn
    private _highlightedEvent: any[] | null = null;

    get highlightedEvent() {
        return this._highlightedEvent
    }

    // open tooltip of higlighted event
    public openTooltip(timestamp: string, evaluation: string, errorCode: string, seriesIndex: number) {
        if (!this._chartInstance || this._eventsDatasetIndex == undefined) return
        // getdimension indexes
        const { _getBulkDimensionIndexes } = dataMethods(this._chartInstance, this._eventsDatasetIndex);
        const { timestampDimensionIndex, evaluationDimensionIndex, errorCodeDimensionIndex } = _getBulkDimensionIndexes(['timestamp', 'evaluation', 'errorCode']);
        if (timestampDimensionIndex == undefined || evaluationDimensionIndex == undefined || errorCodeDimensionIndex == undefined) return;
        //  group containing requested event
        const eventGroup = this._groups?.groups.find((group) => {
            const inGroup = group.find((event) => event[timestampDimensionIndex] === timestamp && event[evaluationDimensionIndex] === evaluation && event[errorCodeDimensionIndex] === errorCode);
            if (inGroup !== undefined) this._highlightedEvent = inGroup;
            return inGroup !== undefined
        });
        if (!eventGroup) return;
        // get first event of group, as this is the only one plotted in the chart for the whole group
        const firstEventOfGroup = eventGroup[0];
        // get index of first event in whole plot dataset for dispatchAction
        const dataIndex = this._eventData.indexOf(firstEventOfGroup);

        // dispatch showTip action on first event of group
        this._chartInstance?.dispatchAction({
            type: 'showTip',
            seriesIndex: seriesIndex,
            dataIndex: dataIndex
        })
    }

    // filter functions for different event sources
    public errorFilter(errorFilters: string[], error: Error): boolean {
        let matchedFilters: boolean[] = [];

        // returns true if filter is present, callable with a single filter string or array
        const hasFilter = (filter: string | string[]): boolean => {
            return typeof (filter) === 'string' 
                ? errorFilters.indexOf(filter) > -1 
                : errorFilters.some((setFilter) => filter.includes(setFilter))
        };

        // test against errorModelInterpretation
        if (hasFilter('criticalOnly')) {
            let entryMatchingFilter = error.errorModelInterpretation === 'Failure'
            // skip all other filters if this filter does not apply
            if (!entryMatchingFilter) return entryMatchingFilter;
        }

        // test against plotted lanes
        if (hasFilter(['evCommunication', 'electrical', 'vendorSpecific'])) {
            const errorDescArray = error.errorDescription.split(':')
            const normalizedErrorDesc = errorDescArray[errorDescArray.length - 1].trim();
            const errorCategory = this._eventIconsService.getEventCategory(normalizedErrorDesc)[0];
            let entryMatchingFilter =
                (hasFilter('electrical') && errorCategory === 'electrical') ||
                (hasFilter('evCommunication') && errorCategory === 'device-communication') ||
                (hasFilter('vendorSpecific') && errorCategory === 'vendor');

            matchedFilters.push(entryMatchingFilter)
        }

        // test against certain events, at least one must match
        const eventsFilter = ['temperature', 'reader', 'connectorLock', 'localList', 'internal', 'reset', 'other'];
        if (hasFilter(eventsFilter)) {
            let matches = [];
            for (let i = 0; i < eventsFilter.length; i++) {
                const filter = eventsFilter[i];
                if (hasFilter(filter)) {
                    matches.push(error.errorCode.toLowerCase().includes(filter.toLowerCase()))
                }
            }
            matchedFilters.push(matches.indexOf(true) > -1);
        }

        // return true if at least one filter matched
        return matchedFilters.indexOf(true) > -1
    }


    //  ==  S T A T E S  == //

    public overallStateTooltipsFactory(
        showHeartbeat: boolean = false,
        showTimestamp: boolean = true,
        loopIndex: number = 0
    ): {
        formatter: (params: any, ticket: string) => any,
        borderWidth: number,
        borderColor: string,
        padding: number,
        position: (point: any[], params: Object | Array<Object>, dom: HTMLElement, rect: DOMRect, size: Object) => any
    } | undefined 
    {
        return this._ngZone.runOutsideAngular(() => {
            const formatterFn = (params: any, ticket: string) => {
                const searchIntervalLength = 20;

                const categoryDimensionIndex = 0;
                const timestampDimensionIndex = 1;
                const evaluationDimensionIndex = 2;

                const state = params.data;
                const loopLength = showHeartbeat ? 5 : 4;

                // get all entries - 15 to + 15 minutes of current timestamp
                const timestamp = state[timestampDimensionIndex];
                const date = new Date(timestamp);
                const searchInterval = {start: subMinutes(date, searchIntervalLength / 2), end: addMinutes(date, searchIntervalLength / 2)};

                // returns matching entry of dataset based on current timestamp
                const getMatchingStates = (dataset: any[][]): any[] => {
                    // all states should be on the exact same timestamp
                    const instantMatch = dataset.find((entry: any) => entry[timestampDimensionIndex] === timestamp);
                    if (instantMatch) return instantMatch
                    // if however no match could be found, we expand our search by the previously defined searchIntervalLength, 
                    // and return the entry closest to our current timestamp
                    const entriesInInterval = dataset.filter((entry: any) => isWithinInterval(new Date(entry[timestampDimensionIndex]), searchInterval));
                    const datesInArray = entriesInInterval.map((entry: any) => new Date(entry[timestampDimensionIndex]));
                    const featuredEntryIndex = entriesInInterval.length == 0 ? 0 : closestIndexTo(date, datesInArray) ?? 0;
                    return entriesInInterval[featuredEntryIndex]
                }

                const dataset = this._chartInstance?.getOption()['dataset'];
                if (!dataset) return

                const featuredHealthIndex = getMatchingStates((dataset as any[])[1+(loopIndex*loopLength)].source as any[]);
                const featuredChargingModel = getMatchingStates((dataset as any[])[2+(loopIndex*loopLength)].source as any[]);
                const featuredErrorModel = getMatchingStates((dataset as any[])[3+(loopIndex*loopLength)].source as any[]);
                const featuredHeartbeat = showHeartbeat ? getMatchingStates((dataset as any[])[4+(loopIndex*loopLength)].source as any[]) : null;
                const healthIndexValue = featuredHealthIndex[evaluationDimensionIndex];
                const healthIndexIsEmpty = healthIndexValue === null || healthIndexValue === undefined || healthIndexValue === 'No Data';

                const displayInfo: DisplayInfo[] = showTimestamp ? [
                    {
                        pre: this._t('COMMON.TIMESTAMP.ONE'),
                        main: (() => {
                            return this._tryDate(state[timestampDimensionIndex], 'HH:mm:ss.SS')
                        })(),
                        sub: (() => {
                            return this._tryDate(state[timestampDimensionIndex], 'MMM d y')
                        })(),
                        show: true
                    }
                ] : [];

                return `
                    <div class="sections-wrapper state-tooltip">
                        <div class="section">
                            <span class="state-pill" style="background-color: ${ params.color };">${ this.localizedStateName.instant(state[evaluationDimensionIndex]) }</span>
                            <p class="info">${this._t('COMMON.MODELS.OVERALL_STATE.ONE')}</p>
                        </div>
                        <div class="section pt-8 pb-8">
                            <p class="pre">${this._t('COMMON.INDICATOR.OTHER')}</p>
                            <div class="flex-row align-items-center justify-content-start">
                                ${this.permService.hasStateModel('lastHealthIndexValue') ? `
                                    <div class="flex-row align-items-center justify-content-start pr-8 ${ this._stateClassPipe.transform(featuredHealthIndex[evaluationDimensionIndex], 'txt') }">
                                        <span class="material-icon">${ healthIndexIsEmpty ? 'gpp_bad' : 'health_and_safety' }</span>
                                        <span>${ healthIndexIsEmpty ? ' - ' : featuredHealthIndex[evaluationDimensionIndex] ?? ' - ' } %</span>
                                    </div>
                                ` : ''}
                                ${this.permService.hasStateModel('lastHeartbeatState') && showHeartbeat && featuredHeartbeat ? `
                                    <span class="pr-8 material-icon ${ this._stateClassPipe.transform(featuredHeartbeat[evaluationDimensionIndex], 'txt') }">
                                        ${featuredHeartbeat[evaluationDimensionIndex] == 'Failure' ? 'heart_broken' : 'favorite'}
                                    </span>
                                ` : '' }
                                ${this.permService.hasStateModel('lastChargingModelState') ? `
                                    <span class="material-icon pr-8 ${ this._stateClassPipe.transform(featuredChargingModel[evaluationDimensionIndex], 'txt') }">ev_station</span>
                                ` : ''}
                                ${this.permService.hasStateModel('lastErrorState') ? `
                                    <span class="material-icon ${ this._stateClassPipe.transform(featuredErrorModel[evaluationDimensionIndex], 'txt') }">warning</span>
                                ` : ''}
                            </div>
                        </div>
                        ${ tooltipContent(displayInfo) }
                    </div>
                    `;
            }

            return {
                formatter: formatterFn,
                borderWidth: 0.5,
                borderColor: '#C4C4C4',
                padding: 12,
                position: defaultTooltipPositionFn
            }
        })
    }


    public singleStateTooltipsFactory(indices: StateTooltipsIndices, type: 'enumerated' | 'health-index' | 'percentage'): {
        formatter: (params: any, ticket: string) => any,
        borderWidth: number,
        borderColor: string,
        padding: number,
        position: (point: any[], params: Object | Array<Object>, dom: HTMLElement, rect: DOMRect, size: Object) => any
    } | undefined 
    {
        return this._ngZone.runOutsideAngular(() => {
            const formatterFn = (params: any, ticket: string) => {

                const {timestampDimensionIndex, evaluationDimensionIndex} = indices;
                const state = params.data;
                const categoryTitle = (indices as any)['categoryTitle'] ?? state[(indices as any)['categoryDimensionIndex']]

                const displayInfo: DisplayInfo[] = [
                    {
                        pre: this._t('COMMON.TIMESTAMP.ONE'),
                        main: (() => {
                            return this._tryDate(state[timestampDimensionIndex], 'HH:mm:ss.SS')
                        })(),
                        sub: (() => {
                            return this._tryDate(state[timestampDimensionIndex], 'MMM d y')
                        })(),
                        show: true
                    }
                ];

                let content: string = '';

                if (type == 'health-index') {
                    content = `
                    <div class="flex-row align-items-center justify-content-start ${ this._stateClassPipe.transform(state[evaluationDimensionIndex], 'txt') }">
                        <span class="material-icon">${ state[evaluationDimensionIndex] == null ? 'gpp_bad' : 'health_and_safety' }</span>
                        <span>${ state[evaluationDimensionIndex] ?? ' - ' } %</span>
                    </div>
                    `;
                } else if (type == 'enumerated') {
                    content = `<span class="state-pill" style="background-color: ${params.color};">${this.localizedStateName.instant(state[evaluationDimensionIndex])}</span>
                                <p class="info">${this._translate.instant(categoryTitle)}</p>`
                } else {
                    displayInfo.unshift({
                        headline: `${state[evaluationDimensionIndex]} %`,
                        info: this._t('DETAILS_VIEW.TIMELINE.MESSAGES_RECEIVED'),
                        show: true
                    })
                }


                return `
                    <div class="sections-wrapper state-tooltip">
                        <div class="section">
                            ${content}
                        </div>
                        ${tooltipContent(displayInfo)}
                    </div>
                    `;
            }

            return {
                formatter: formatterFn,
                borderWidth: 0.5,
                borderColor: '#C4C4C4',
                padding: 12,
                position: defaultTooltipPositionFn
            }
        })
    }

    // Calculate the interval in which to show ticks depending on the shown time range
    public calcTickInterval(rangeStart: number, rangeEnd: number) : number {
        const totalInterval = rangeEnd - rangeStart;
        const relativeInterval = (this._currentZoomLevels.end - this._currentZoomLevels.start) / 100; // Zoom is normalized between 0 and 100
        const selectedInterval = totalInterval * relativeInterval;
        
        if (selectedInterval < 1000 * 20) {
            // Under 20 seconds, show every second
            return 1 + 1000 * 1;
        } else if (selectedInterval < 1000 * 60 * 1.8) {
            // Under 2 minutes, show every 10 seconds
            return 1 + 1000 * 10;
        } else if (selectedInterval < 1000 * 60 * 20) {
            // Under 20 minutes, show every minute
            return 1 + 1000 * 60 * 1;
        } else if (selectedInterval < 1000 * 3600 * 1.8) {
            // Under 2 hours, show every 10 minutes
            return 1 + 1000 * 60 * 10;
        } else if (selectedInterval < 1000 * 3600 * 16) {
            // Under 16 hours, show every 1 hour
            return 1 + 1000 * 3600;
        } else if (selectedInterval < 1000 * 3600 * 36) {
            // Under 36 hours, show every 6 hours
            return 1 + 1000 * 3600 * 6;
        } else if (selectedInterval < 1000 * 3600 * 24 * 12) {
            // Under 12 days, show every day
            return 1 + 1000 * 3600 * 24;
        } else if (selectedInterval < 1000 * 3600 * 24 * 7 * 10) {
            // Under 10 weeks, show every two weeks
            return 1 + 1000 * 3600 * 24 * 14;
        } else if (selectedInterval < 1000 * 3600 * 24 * 30 * 8) {
            // Under 8 months, show every month
            return 1 + 1000 * 3600 * 24 * 30;
        } else if (selectedInterval < 1000 * 3600 * 24 * 365 * 3) {
            // Under 3 years, show every quarter
            return 1 + 1000 * 3600 * 24 * 91;
        } else {
            // Show every year
            return 1 + 1000 * 3600 * 24 * 365;
        }
    }
}
