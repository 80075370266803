import { animate, animateChild, group, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, computed, effect, input, model, OnDestroy, output, Renderer2, Signal, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// provide TemplateRef from parent for full control of popup
type PopupTemplate = {
    template: TemplateRef<any>
}

// single action in list
type Action = {
    title: string
    id: string
    tooltip?: string | ((selectedIds: IDs) => string | null)
}

// List of actions which will be emitted on click
type ActionList = {
    actions: Array<Action & Conditions>
}

type IDs = (string | number)[];

// panel to confirm action
type ConfirmationPanel = {
    confirmation: {
        title: string
        text: string | ((selectedIds: IDs) => string)
        button: {
            text: string
            icon?: string
        }
    }
}

// empty type for optional InstantAction (no PopupTemplate, ActionList or ConfirmationPanel)
type InstantAction = {}

// Conditions for showing or disabling action
type Conditions = {
    conditions?: {
        show?: boolean | ((selectedIds: IDs) => boolean)
        disabled?: boolean | ((selectedIds: IDs) => boolean)
        tooltip?: string | ((selectedIds: IDs) => string | null)
    }
}

export type BulkSelectTextTemplate = string | ((selectedIds: IDs) => string);

// main BulkSelectAction type
export type BulkSelectAction = {
    id: string
    title: string
    icon?: string
} & Conditions & (PopupTemplate | ActionList | ConfirmationPanel | InstantAction);

// Internal types only to be used in component
// properties should not be set from outside
type InternalActionList = {
    actions: Array<Action & Conditions & {
        show: boolean
        disabled: boolean
        formattedTooltip: string | null
    }>
}

type InternalBulkSelectAction = {
    // additional properties for internal use
    open: boolean
    disabled: boolean
    tooltip?: string | null
    formattedConfirmationText?: string
    // base properties form BulkSelectAction
    id: string
    title: string
    icon?: string
} & Conditions & (PopupTemplate | InternalActionList | ConfirmationPanel | InstantAction);

@Component({
    selector: 'evc-table-bulk-panel',
    template: `
        @if (vm(); as vm) {
            @if (vm.selectedIds.length > 0) {
                <div 
                    class="actions-wrapper flex-row align-items-center justify-content-center"
                    [@panelInOut]
                >
                    <button 
                        class="close-panel"
                        type="button"
                        (click)="selectedIds.set([])"
                    >
                        <span class="material-icon">clear</span>
                    </button>
                    <p class="selected-text">{{ vm.selectedText }}</p>
                    <div 
                        class="buttons-parent-wrapper flex-row align-items-center justify-content-between"
                        [@buttonsInOut]
                    >
                        @for (action of vm.options; track action.title) {
                            <div class="button-wrapper">
                                <button
                                    evc-button
                                    [icon]="action.icon"
                                    [disabled]="action.disabled"
                                    [tooltip]="action.tooltip || null"
                                    size="small"
                                    type="button"
                                    [toggled]="action.open"
                                    (click)="handleClick(action, vm.selectedIds)"
                                >{{ action.title }}</button>
                                @if (action.open) {
                                    <div 
                                        class="popup"
                                        [@popupInOut]
                                    >
                                        @if (isPopupTemplate(action)) {
                                            <ng-container *ngTemplateOutlet="action.template; context: {$implicit: vm.selectedIds}"></ng-container>
                                        }
                                        @if (isActionList(action)) {
                                            <div class="actions-list">
                                                <h3>{{ action.title }}</h3>
                                                @for (subAction of action.actions; track subAction.id) {
                                                    @if (subAction.show) {
                                                        <button
                                                            evc-button
                                                            variant="flat"
                                                            type="button"
                                                            [tooltip]="subAction.formattedTooltip || null"
                                                            [disabled]="subAction.disabled"
                                                            [size]="'small'"
                                                            (click)="emitAction(subAction.id, vm.selectedIds)"
                                                        >
                                                            {{ subAction.title }}
                                                        </button>
                                                    }
                                                }
                                            </div>
                                        }
                                        @if (isConfirmationPanel(action)) {
                                            <div class="confirmation-panel">
                                                <h3>{{ action.confirmation.title }}</h3>
                                                <p>{{ action.formattedConfirmationText }}</p>
                                                <button 
                                                    evc-button
                                                    variant="colored"
                                                    [icon]="action.confirmation.button.icon"
                                                    type="button"
                                                    (click)="emitAction(action.id, vm.selectedIds)"
                                                >
                                                    {{ action.confirmation.button.text }}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        }
    `,
    styleUrl: './table-bulk-panel.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('panelInOut', [
            transition(':enter', [
                style({ transform: 'translateY(70px)', opacity: 0, scale: .8 }),
                animate('.25s cubic-bezier(.78,.66,.27,1)', style({ transform: 'translateY(0px)', opacity: 1, scale: 1})),
                query('@buttonsInOut', [
                    animateChild()
                ]),
            ]),
            transition(':leave', [
                group([
                    query('@buttonsInOut', [
                        animateChild()
                    ]),
                    style({ transform: 'translateY(0px)', opacity: 1, scale: 1 }),
                    animate('.25s cubic-bezier(.78,.66,.27,1)', style({ transform: 'translateY(70px)', opacity: 0, scale: .8 })),
                ])
            ])
        ]),
        trigger('buttonsInOut', [
            transition(':enter', [
                query('.button-wrapper', [
                    style({ transform: 'translateY(20px)', opacity: 0 }),
                    stagger(45, [
                        animate('.18s ease-out',
                            style({ transform: 'translateY(0px)', opacity: 1 })
                        )
                    ])
                ])
            ]),
            transition(':leave', [
                query('.button-wrapper', [
                    style({ transform: 'translateY(0px)', opacity: 1 }),
                    stagger(-45, [
                        animate('.18s ease-in',
                            style({ transform: 'translateY(20px)', opacity: 0 })
                        )
                    ])
                ])
            ])
        ]),
        trigger('popupInOut', [
            transition(':enter', [
                style({ transform: 'translateY(15px)', opacity: 0 }),
                animate('.15s cubic-bezier(.78,.66,.27,1)', style({ transform: 'translateY(0px)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0px)', opacity: 1 }),
                animate('.15s cubic-bezier(.78,.66,.27,1)', style({ transform: 'translateY(15px)', opacity: 0 }))
            ])
        ])
    ]
})
export class TableBulkPanelComponent implements OnDestroy {
    // Inputs
    public selectedTextTemplate = input<BulkSelectTextTemplate>();
    public options = input<BulkSelectAction[]>();
    // Outputs
    public onAction = output<{action: string, selectedIds: (string | number)[]}>();
    // Array of selected row ids
    public selectedIds = model<(string | number)[] | null>();
    // id of open action, can be controlled by parent (useful for PopupTemplate handling)
    public openedAction = model<BulkSelectAction['id'] | null>(null);
    // handle esc key functions
    private _unlistenKeyEvents: (() => void) | null = null;

    // view model
    public vm: Signal<{
        selectedIds: (string | number)[],
        options: InternalBulkSelectAction[] | undefined,
        selectedText: string
    }>;

    // Type guards to check action types
    isPopupTemplate(action: BulkSelectAction): action is BulkSelectAction & PopupTemplate {
        return (action as PopupTemplate).template !== undefined;
    }
    isActionList(action: BulkSelectAction): action is BulkSelectAction & (ActionList | InternalActionList) {
        return (action as ActionList).actions !== undefined;
    }
    isConfirmationPanel(action: BulkSelectAction): action is BulkSelectAction & ConfirmationPanel {
        return (action as ConfirmationPanel).confirmation !== undefined;
    }

    constructor (
        private _renderer: Renderer2,
        private _translate: TranslateService
    ) {
        this.vm = computed(() => {
            const selectedIds = this.selectedIds() ?? [];
            const mainOptions = this.options();
            const activeAction = this.openedAction();
            // convert options to internal model, filter out options which should not be shown and set flags
            const options: InternalBulkSelectAction[] = (mainOptions ?? []).reduce((acc, option) => {
                if (!option.conditions || option.conditions.show === undefined || (typeof option.conditions.show == 'boolean' && option.conditions.show || typeof option.conditions.show == 'function' && option.conditions.show(selectedIds))) {
                    const open = option.id === activeAction;
                    // If open and ConfirmationPanel, call text method (if not string) and write to confirmation.text property
                    if (open && this.isConfirmationPanel(option)) {
                        (option as InternalBulkSelectAction).formattedConfirmationText = typeof option.confirmation.text === 'string'
                            ? option.confirmation.text
                            : option.confirmation.text(selectedIds)
                    }

                    if (open && this.isActionList(option)) {
                        option.actions = option.actions.map((action) => {
                            return {
                                ...action,
                                show: action.conditions && action.conditions.show !== undefined ? typeof action.conditions.show == 'function' ? action.conditions.show(selectedIds) : action.conditions.show : true,
                                disabled: action.conditions && action.conditions.disabled !== undefined ? typeof action.conditions.disabled == 'function' ? action.conditions.disabled(selectedIds) : action.conditions.disabled : false,
                                formattedTooltip: typeof action.tooltip === 'function' ? action.tooltip(selectedIds) : action.tooltip ?? null
                            }
                        })
                    }

                    acc.push({
                        ...option,
                        disabled: option.conditions && option.conditions.disabled !== undefined ? typeof option.conditions.disabled == 'function' ? option.conditions.disabled(selectedIds) : option.conditions.disabled : false,
                        tooltip: typeof option.conditions?.tooltip === 'function' ? option.conditions.tooltip(selectedIds) : option.conditions?.tooltip,
                        open
                    });
                }
                return acc;
            }, [] as InternalBulkSelectAction[]);
            const textTemplate = this.selectedTextTemplate();
            let textContent: string;
            if (textTemplate) {
                textContent = typeof textTemplate == 'string' 
                    ? textTemplate
                    : textTemplate(selectedIds)
            } else {
                textContent = selectedIds.length == 1 
                    ? this._translate.instant('TABLES.BULK_ACTIONS.SELECTED.ONE', {n: selectedIds.length})
                    : this._translate.instant('TABLES.BULK_ACTIONS.SELECTED.OTHER', {n: selectedIds.length})
            }

            return {
                selectedIds: selectedIds,
                selectedText: textContent,
                options: options as InternalBulkSelectAction[]
            }
        });

        // listen to esc key, close active popup when no items selected
        effect(() => {
            const selectedIds = this.selectedIds();
            if (selectedIds && selectedIds.length > 0 && this._unlistenKeyEvents == null) {
                // listen to esc key
                this._unlistenKeyEvents = this._renderer.listen('document', 'keydown.esc', (event) => {
                    if (this.openedAction() !== null) {
                        // close active popup
                        this.openedAction.set(null)
                    } else {
                        // close bulk actions panel
                        this.selectedIds.set([])
                    }
                })
            } else if ((!selectedIds || selectedIds.length == 0) && this._unlistenKeyEvents !== null) {
                this._removeListener();
                // close popup if active
                if (this.openedAction() !== null) {
                    this.openedAction.set(null)
                }
            }
        }, {allowSignalWrites: true})
    }

    public handleClick(option: InternalBulkSelectAction, selectedIds: (string | number)[]) {
        if (this.isPopupTemplate(option) || this.isActionList(option) || this.isConfirmationPanel(option)) {
            const activeAction = this.openedAction();
            if (activeAction == option.id) {
                this.openedAction.set(null);
            } else {
                this.openedAction.set(option.id);
            }
        } else {
            // in case none of the additional types match, handle this as an instant action and emit
            this.emitAction(option.id, selectedIds);  
        }
    }

    public emitAction(actionId: string, selectedIds: (string | number)[]) {
        this.onAction.emit({action: actionId, selectedIds});
        this.openedAction.set(null);
    }

    // removes eventListener on esc if set
    private _removeListener() {
        if (this._unlistenKeyEvents !== null) {
            this._unlistenKeyEvents();
            this._unlistenKeyEvents = null;            
        }
    }

    ngOnDestroy(): void {
        this._removeListener()
    }
}
