import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Signal, ViewChild, WritableSignal, computed, effect, input, signal } from '@angular/core';
import { selectFilterOption } from '../select-filters/select-filters.component';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, catchError, combineLatest, concat, debounceTime, delay, distinctUntilChanged, firstValueFrom, from, map, mergeMap, of, share, startWith, switchMap, take, tap, toArray, withLatestFrom } from 'rxjs';
import { Alert, FilterSet, SharedFilterSet } from 'src/app/core/data-backend/models';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { NotificationService } from 'src/app/core/app-services';
import { OverviewCacheService } from 'src/app/core/app-services/overview-cache.service';
import { Filter, stationFiltersRepository } from 'src/app/core/stores/station-filters.repository';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, FilterService, FilterSetService } from 'src/app/core/data-backend/data-services';
import { decodeString } from 'src/app/core/helpers/utils.helper';

@Component({
    selector: 'evc-filterset-mask',
    template: `
        <form
            class="filtersetForm"
            name="createFilterSet"
            [formGroup]="createFilterSetForm"
            (ngSubmit)="onSubmit()"
        >
            @if (canSwitchType) {
                <div class="tabs">
                    <radio-button-group
                        class="rbgroup"
                        [options]="[
                            {
                                label: ('FILTERS.OWN_FILTER_SET.ONE' | translate),
                                value: 'default'
                            },
                            {
                                label: ('FILTERS.SHARED_FILTER_SET.ONE' | translate),
                                value: 'shared'
                            }
                        ]"
                        [type]="'line'"
                        [activeSelection]="type$ | async"
                        (activeSelectionChange)="type$.next($event == 'default' ? 'default' : 'shared')"
                    ></radio-button-group>
                </div>
            }
            <div class="position-relative pb-8">
                <input 
                    type="text"
                    [placeholder]="namePlaceholder()"
                    formControlName="name" 
                    required
                    #nameInput
                    (change)="createFilterSetForm.patchValue({name: nameInput.value})"
                >
                <span class="error" *ngIf="nameError as error">{{ error }}</span>
            </div>
            <div class="flex-row align-items-end">
                <p class="headline pt-16">
                    <span 
                        class="material-icon"
                        [class.state-failure-txt]="filtersError"
                    >filter_alt</span> {{ 'FILTERS.FILTER.OTHER' | translate }}
                </p>
                <span class="state-failure-txt pl-16" *ngIf="filtersError as error">{{ error }}</span>
            </div>

            <div class="wrapper">
                <div class="select-filters-wrapper">
                    <select-filters
                        [filterOptions]="availableFilters$ | async"
                        [showFiltersetSelection]="false"
                        [disabled]="createFilterSetForm.disabled"
                        [activeFilters]="activeFilterKeys$ | async"
                        (activeFiltersChange)="updateSelection($event)"
                    >
                    </select-filters>
                </div>
                <div class="filters">
                    <div class="text-center" *ngIf="showPreloader$ | async">
                        <app-preloader
                            [type]="'squares'"
                        >
                        </app-preloader>
                    </div>
                    <list-filters
                        [filters]="mappedActiveFilters$ | async"
                        [loading]="filtersPolling$ | async"
                        [disabled]="createFilterSetForm.disabled"
                        (onDeleteFilter)="deleteFilter($event)"
                        (onResetFilter)="resetFilter($event)"
                        (onNewFilterValue)="updateFilter($event)"
                    >
                    </list-filters>
                </div>
            </div>

            <p class="headline pt-16"><span class="material-icon">notifications</span> {{ 'ALERTS.ALERTING' | translate }}</p>
            <div class="wrapper" formGroupName="alert">
                <p
                    class="subline pb-16"
                    [innerHTML]="'ALERTS.ALERT_INFO' | translate"
                ></p>
                <div class="toggle-alert flex-row align-items-center justify-content-start">
                    <label class="pl-8 flex-row align-items-center justify-content-start">
                        <input type="checkbox" formControlName="toggled" name="toggled" id="toggled">
                        <span class="pl-8 pr-8">{{ 'ALERTS.ALERT_WHEN' | translate }}</span>
                    </label>
                    <div class="flex-column button-wrapper pl-8" *ngIf="createFilterSetForm.get('alert.condition') as alertCondition">
                        <button type="button" 
                            evc-button
                            [toggled]="alertCondition.value == 'gt'"
                            [disabled]="createFilterSetForm.disabled"
                            (click)="createFilterSetForm.patchValue({alert: {condition: 'gt'}})"
                        >{{ 'ALERTS.MORE_THAN' | translate }}</button>
                        <button type="button" 
                            evc-button
                            [toggled]="alertCondition.value == 'lt'"
                            [disabled]="createFilterSetForm.disabled"
                            (click)="createFilterSetForm.patchValue({alert: {condition: 'lt'}})"
                        >{{ 'ALERTS.LESS_THAN' | translate }}</button>
                    </div>
                    <div class="flex-row align-items-center justify-content-start position-relative">
                        <input type="number" 
                            class="pl-8" 
                            step="1"
                            formControlName="numOfChargers"
                            #numOfChargersInput
                            [ngClass]="{'invalid': this.validateNumOfChargers(createFilterSetForm.value.alert)}"
                            (change)="createFilterSetForm.patchValue({alert: {numOfChargers: numOfChargersInput.valueAsNumber}})"
                        >
                        <p class="pl-8">{{ 'ALERTS.CHARGERS_APPLY' | translate }}</p>
                        <span class="error" *ngIf="createFilterSetForm.errors?.['numOfChargers'] as error">{{ error }}</span>
                    </div>
                </div>
                <p
                    class="subline pt-16"
                    [innerHTML]="'ALERTS.MAIL_INFO' | translate"
                ></p>
                <div class="toggle-alert flex-row align-items-center justify-content-start pt-16">
                    <label class="pl-8 flex-row align-items-center justify-content-start">
                        <input type="checkbox" formControlName="sendMail" name="sendMail" id="sendMail">
                        <span class="pl-8 pr-8">{{ 'ALERTS.RECEIVE_MAIL' | translate }}</span>
                    </label>
                    <label class="pl-8 flex-row align-items-center justify-content-start">
                        <input type="checkbox" formControlName="detailed" name="detailed" id="detailed">
                        <span class="pl-8 pr-8">{{ 'ALERTS.DETAILED_MAIL' | translate }}</span>
                    </label>
                </div>
            </div>

            <div class="footer flex-row align-items-center justify-content-end">
                @if (showCancel) {
                    <button 
                        evc-button
                        type="button"
                        btnSize="lg"
                        class="mr-16" 
                        (click)="onCancel.emit()"
                    >
                        {{ 'COMMON.CANCEL' | translate }}
                    </button>
                }
                @if (mode() != 'preview') {
                    <button 
                        evc-button
                        variant="colored"
                        btnSize="lg"
                        type="submit"
                        [icon]="buttonIcon()"
                    >
                        {{ buttonText() }}
                    </button>
                }
            </div>
        </form>
  `,
    styleUrls: ['./filterset-mask.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersetMaskComponent {
    // text in submit button shows user info abt request
    public buttonText: Signal<string>;
    public buttonIcon: Signal<string>;
    // loading state after POST / PUT request
    public isLoading: WritableSignal<boolean> = signal(false);
    // placeholder for name field
    public namePlaceholder: Signal<string>;
    // all available filters as select options (not full Filter Type), prepared by parent
    public availableFilters$ = new BehaviorSubject<selectFilterOption[]>([]);
    @Input() set availableFilters(values: selectFilterOption[] | null) {
        if (!values) return;
        this.availableFilters$.next(values);
    }
    // all already available base filter objects
    public preBaseFilters = input<Filter[] | null>(null, {alias: 'baseFilters'});
    private _preBaseFilters$ = toObservable(this.preBaseFilters);
    // preset filters with values
    private _selectedFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
    @Input() set preselectedFilters(values: Filter[] | null) {
        // update selected filters with pre selected values
        this._selectedFilters$.next(structuredClone(values) ?? [])
    };
    public mappedActiveFilters$: Observable<Filter[]>;
    public filtersPolling$: Observable<boolean>;
    // currently available filterSets, to prevent duplicate ids
    @Input() currentFilterSets: FilterSet[] | SharedFilterSet[] | null = null;
    // filter set to be edited
    private _editFilterset: FilterSet | SharedFilterSet | null = null;
    @Input() set editFilterset(filterset: FilterSet | SharedFilterSet | null) {
        if (!filterset) return;
        this._editFilterset = filterset;
        this.createFilterSetForm.patchValue({
            name: filterset.name,
        });
        // add shared filter set tenant to definition
        if (
            filterset.isShared &&
            'tenant' in filterset && 
            !filterset.filterSetDefinition.find(filter => filter.id == 'tenant')
        ) {
            filterset.filterSetDefinition.unshift({
                id: 'tenant',
                value: filterset.tenant
            })
        }
        // set current filter config to selectedFilters
        const selectedFilters: Filter[] = filterset.filterSetDefinition
            .filter((def) => def.id != null && def.id != undefined)
            .map((def) => ({
                // create empty Filter object
                // will be filled with the actual filterOptions in the selectedFilters$ pipe
                id: def.id!,
                label: this._filterRepo.getFilterName(def.id!),
                type: 'select-multiple',
                value: def.value
            }))
        this.preselectedFilters = selectedFilters;
    };
    // alert to be edited
    private _editAlert: Alert | null = null;
    @Input() set editAlert(alert: Alert | null) {
        this._editAlert = alert;
        this.createFilterSetForm.patchValue({
            alert: {
                toggled: alert !== null,
                condition: alert?.operator ?? 'gt',
                numOfChargers: alert?.value ?? 10,
                sendMail: alert?.sendMail ?? false,
                detailed: alert?.detailed ?? false,
            }
        })
    }
    // mapped keys of active filters
    public activeFilterKeys$: Observable<string[]> = this._selectedFilters$.pipe(
        map((filters) => filters.map((filter) => filter.id)),
    )
    public createFilterSetForm = new FormGroup({
        name: new FormControl<string>(''),
        filters: new FormControl<{id: string, value: any}[]>([]),
        alert: new FormGroup({
            toggled: new FormControl<boolean>(false),
            condition: new FormControl<'gt'|'eq'|'lt'>('gt'),
            numOfChargers: new FormControl<number>(10),
            sendMail: new FormControl<boolean>(false),
            detailed: new FormControl<boolean>(false),
        })
    }, {validators: [
        (control: AbstractControl): ValidationErrors | null => {
            const alert = control.get('alert')?.value;
            return this.validateNumOfChargers(alert);
        },
        (control: AbstractControl): ValidationErrors | null => {
            const name = control.get('name')?.value;
            return !name || name.length == 0 ? {
                name: this._t('FILTERS.FORM.NAME_REQUIRED')
            } : null
        },
        (control: AbstractControl): ValidationErrors | null => {
            const filters = control.get('filters')?.value;
            if (!filters || filters.length == 0) return {
                filters: this._t('FILTERS.FORM.FILTERS_REQUIRED')
            }
            for (const filter of filters) {
                if (!filter.value || filter.value.length == 0) return {
                    filters: this._t('FILTERS.FORM.FILTER_NO_VALUE')
                }
            }
            if (this.type$.value == 'shared' && filters.length == 1) return {
                filters: this._t('FILTERS.FORM.MUST_SELECT_MORE')
            }
            return null;
        }
    ]})
    // helpers for better user feedback when errors in filters form occur
    public triedFormSubmit = false;
    get filtersError() {
        return this.triedFormSubmit && this.createFilterSetForm.errors?.['filters'];
    }
    get nameError() {
        return this.triedFormSubmit && this.createFilterSetForm.errors?.['name'];
    }
    // focus input element once in DOM
    @ViewChild('nameInput') set nameInput(nameInput: ElementRef | undefined) {
        nameInput?.nativeElement.focus()
    };
    @Input() showCancel: boolean = false;
    // whether a filter set is created, updated or just shown
    mode = input<'create' | 'update' | 'preview'>('create');
    // whether a filter set is shared or not
    public type$ = new BehaviorSubject<'default' | 'shared'>('default');
    @Input() set type(state: 'default' | 'shared' | null) {
        if (state) this.type$.next(state);
    };
    // whether filter set type can be changed or not
    @Input() canSwitchType: boolean = true;

    // Outputs
    @Output() onCancel = new EventEmitter<void>();
    @Output() onError = new EventEmitter<string>();
    @Output() onSuccess = new EventEmitter<void>();
    @Output() switchType = new EventEmitter<'default' | 'shared'>();

    public showPreloader$: Observable<boolean>;

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    constructor(
        private _filterService: FilterService,
        private _filterSetService: FilterSetService,
        private _alertService: AlertService,
        private _notificationService: NotificationService,
        private _overviewCacheService: OverviewCacheService,
        private _filterRepo: stationFiltersRepository,
        private _translate: TranslateService
    ) {
        const type = toSignal(this.type$);
        const langChanged = toSignal(this._translate.onLangChange);
        this.buttonText = computed(() => {
            // call langChanged to update the title when the language changes
            const newLang = langChanged();
            const formType = type();
            const formMode = this.mode();

            if (this.isLoading()) return this._t('COMMON.LOADING.DEFAULT') + '...';

            const localizedFiltersetType = this._t(formType == 'shared' ? 'FILTERS.SHARED_FILTER_SET.ONE' : 'FILTERS.FILTER_SET.ONE');

            return this._t(formMode == 'create' ? 'FILTERS.MANAGE.CREATE_FILTER_SET' : 'FILTERS.MANAGE.SAVE_FILTER_SET', {content: localizedFiltersetType});
        })

        this.buttonIcon = computed(() => {
            if (this.isLoading()) return ''
            return this.mode() == 'create'
                ? 'add'
                : 'save'
        })

        effect(() => {
            // disable form if preview
            if (this.mode() == 'preview') {
                this.createFilterSetForm.disable();
            }
        })

        this.namePlaceholder = computed(() => {
            const newLang = langChanged();
            const formType = type();

            return formType == 'shared' ? this._t('FILTERS.FORM.NAME_SHARED_FILTER_SET') : this._t('FILTERS.FORM.NAME_FILTER_SET');
        })

        // align detailed state with sendMail field
        const alertGroup = this.createFilterSetForm.get('alert');
        if (alertGroup) {
            const sendMailControl = alertGroup.get('sendMail');
            const detailedControl = alertGroup.get('detailed');
            if (sendMailControl && detailedControl) {
                sendMailControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((sendMail) => {
                    if (!sendMail) {
                        detailedControl.setValue(false);
                        detailedControl.disable();
                    } else {
                        detailedControl.enable();
                    }
                });
                if (!sendMailControl.value) {
                    detailedControl.setValue(false);
                    detailedControl.disable();
                }
            }
        }

        const baseFiltersLoading$ = new BehaviorSubject<boolean>(true);
        const updatedFiltersLoading$ = new BehaviorSubject<boolean>(true);
        // base filter objects with all available options
        // (called without any set filters in query)
        const baseFilters$ = this._selectedFilters$.pipe(
            debounceTime(10),
            // combine with latest value of previously available baseFilters
            withLatestFrom(this._preBaseFilters$),
            switchMap(([selectedFilters, baseFilters]) => {
                const baseFilterIds = (baseFilters || []).map((filter) => filter.id);
                let newFilterIds = selectedFilters
                    .filter((filter) => !baseFilterIds?.includes(filter.id))
                    .map((filter) => filter.id);
                // only set loading when new filters need fetching
                baseFiltersLoading$.next(newFilterIds.length > 0)

                // fetch base for all new filters
                return concat(
                    // start with current available base filters
                    of(baseFilters),
                    // concat with new filters, if needed
                    from(newFilterIds).pipe(
                        take(newFilterIds.length),
                        mergeMap((id) =>
                            this._filterService.getFilterOption({
                                filterVariable: id
                            })
                        ),
                        toArray(),
                        this._filterRepo.transformFilter(),
                        // combine last baseFilters with new results
                        map((newBaseFilters) => [...(baseFilters ?? []), ...(newBaseFilters ?? [])]),
                        tap(() => baseFiltersLoading$.next(false))
                    )
                )
            }),
            share({ connector: () => new ReplaySubject(1) })
        )

        // filters with combinable options
        const updatedFilterOptions$ = this._selectedFilters$.pipe(
            debounceTime(10),
            tap((filters) => {
                // updates filter set definition in formGroup
                const filterSetDefinition = filters
                    .map((filter) => {
                        return {
                            id: filter.id,
                            value: filter.value
                        }
                    });

                this.createFilterSetForm.patchValue({
                    filters: filterSetDefinition
                })
            }),
            switchMap((filters) => {
                const cachedFilters$ = this._filterRepo.baseFilterOptions$.pipe(
                    map(({ data }) => data)
                );
                // return all cached filterOptions if no values are selected
                const filterVals = filters.flatMap((filter) => filter.value);
                if (filterVals.length === 0) {
                    updatedFiltersLoading$.next(false)
                    return cachedFilters$
                }

                let filterQuery = filters.map((filter) => {
                    if (filter.value == undefined || filter.value.length === 0) return null
                    let filterObj: any = {};
                    filterObj[filter.id] = filter.value;
                    return filterObj;
                }).filter(x => x !== null);

                updatedFiltersLoading$.next(true)

                return concat(
                    firstValueFrom(cachedFilters$), // use cached value first
                    // then get new filter options, with current set filters
                    from(filters).pipe(
                        take(filters.length),
                        mergeMap((filter) =>
                            this._filterService.getFilterOption({
                                filterVariable: filter.id,
                                filter: filterQuery as any
                            })
                        ),
                        toArray(),
                        tap(() => updatedFiltersLoading$.next(false))
                    )
                )
            }),
            this._filterRepo.transformFilter(),
            share({ connector: () => new ReplaySubject(1) })
        )


        this.mappedActiveFilters$ = combineLatest([
            this._selectedFilters$.pipe(
                debounceTime(10)
            ),
            baseFilters$,
            updatedFilterOptions$.pipe(
                startWith([])
            ),
        ]).pipe(
            withLatestFrom(this.type$),
            map(([[selectedFilters, baseFilters, combineableFilters], type]) => {
                let out: Filter[] = [];
                // selectedFilters: from FE
                selectedFilters.forEach((selectedFilter) => {
                    if (selectedFilter.id == 'tenant' && type == 'shared') {
                        // find base tenant filter
                        let baseTenantFilter = baseFilters?.find((filter) => filter.id === 'tenant');
                        if (baseTenantFilter) {
                            // push required tenant filter
                            out.push({
                                ...selectedFilter,
                                label: `${this._t('FILTERS.FORM.TENANT')} (${this._t('COMMON.REQUIRED')})`,
                                type: 'select-single',
                                required: true,
                                value: selectedFilter?.value ?? null,
                                options: baseTenantFilter.options,
                                inactiveValues: []
                            })
                        } else {
                            // push empty placeholder
                            out.push({
                                id: 'tenant',
                                label: `${this._t('FILTERS.FORM.TENANT')} (${this._t('COMMON.REQUIRED')})`,
                                type: 'select-single',
                                required: true,
                                value: selectedFilter?.value ?? null
                            });
                        }
                    } else {
                        // filter / availableFilters: from BE
                        let filter = baseFilters?.find((filter) => filter.id === selectedFilter.id);
                        if (filter) {
                            // find filter in combinableFilters, which holds the actually selectable options
                            const combineableFilter = combineableFilters?.find((filter) => filter.id === selectedFilter.id);
                        
                            // compare options from allFilters obj with new combinableFilter obj, add non-overlapping as "inactive"
                            // if current filter cant be found in combinableFilter, flag all no options as inactive
                            if (combineableFilter) {
                                const combineableFilterOptionValues = combineableFilter?.options?.map((option) => option.value);
                                const inactiveOptions = combineableFilterOptionValues 
                                    ? filter.options?.filter((option) => combineableFilterOptionValues.indexOf(option.value) == -1)
                                    : filter.options; // flag all options as inactive if combineable filter is found but not value is given by the be

                                filter.inactiveValues = [...new Set(inactiveOptions?.map((x) => x.value))];
                            }
                        
                            if (filter.type === 'range' && filter.rangePickerOptions) {
                                // return new filter with correct rangePickerOptions
                                if (combineableFilter && combineableFilter.rangePickerOptions) {
                                    filter = {...combineableFilter}
                                }
                            
                                const values = selectedFilter.value;
                                if (values !== null && values !== undefined) {
                                    filter.rangePickerOptions!.firstStart = values[0] == undefined ? filter.rangePickerOptions!.min : typeof (values[0]) == 'number' ? values[0] : JSON.parse(values[0]);
                                    filter.rangePickerOptions!.secondStart = values[1] == undefined ? filter.rangePickerOptions!.max : typeof (values[1]) == 'number' ? values[1] : JSON.parse(values[1]);
                                }
                            }

                            // set proper empty value if none available
                            filter.value = selectedFilter.value === null || selectedFilter.value === undefined || selectedFilter.value.length == 0
                                ? this._filterRepo.getEmptyFilterValue(filter)
                                : selectedFilter.value;

                        
                            out.push(filter)
                        } else {
                            // baseFilters are still fetching at this point, so we're returning the provided placeholder until values are present
                            out.push(selectedFilter)
                        }
                    }
                })
                return out
            })
        )

        this.type$.pipe(
            takeUntilDestroyed(),
            distinctUntilChanged(),
            withLatestFrom(this._selectedFilters$, this.availableFilters$),
            tap(([type, activeFilters, availableFilters]) => {
                // emit output function
                this.switchType.emit(type);

                // remove old tenant filter
                const tenantIndex = activeFilters.findIndex(filter => filter.id === 'tenant');
                if (tenantIndex !== -1) activeFilters.splice(tenantIndex, 1);
                
                // find tenant option
                const tenantOption = availableFilters.find(filter => filter.value === 'tenant');

                if (type == 'default') {
                    // make tenant option editable
                    if (tenantOption) tenantOption.required = false;
                } else if (type == 'shared') {
                    // add placeholder tenant filter to beginning of list
                    activeFilters.unshift({
                        id: 'tenant',
                        label: this._filterRepo.getFilterName('tenant'),
                        type: 'select-single',
                        value: null
                    });
                    // make tenant option non editable
                    if (tenantOption) tenantOption.required = true;
                }

                // update filters subjects
                this._selectedFilters$.next(activeFilters);
                this.availableFilters$.next(availableFilters);
            })
        ).subscribe();

        this.filtersPolling$ = combineLatest([
            baseFiltersLoading$,
            updatedFiltersLoading$
        ]).pipe(
            map((bools) => bools.includes(true))
        )

        this.showPreloader$ = this.filtersPolling$.pipe(
            withLatestFrom(this.mappedActiveFilters$.pipe(startWith([]))),
            map(([loading, filters]) => {
                return filters.length == 0 && loading
            })
        )
    }

    // validator for the numOfChargers input field
    public validateNumOfChargers(alert: any): ValidationErrors | null {
        const numOfChargers = alert?.numOfChargers;
        const numIsEmpty = numOfChargers === null || isNaN(numOfChargers);
        const numIsValid = numOfChargers >= 0;
        const validCharactersRegex = /[^0-9]/;
        const numContainsInvalidCharacters = validCharactersRegex.test(numOfChargers);
        return alert?.toggled && (!numIsValid || numIsEmpty || numContainsInvalidCharacters) ? {
            numOfChargers: 
                numContainsInvalidCharacters ? 'Only positive natural numbers are allowed' :
                numIsEmpty ? this._t('FILTERS.FORM.NUM_OF_CHARGERS_REQUIRED') : 
                this._t('FILTERS.FORM.NUM_OF_CHARGERS_GREATER')
        } : null
    }

    // update current filter selection
    public updateSelection(selectedKeys: string[]) {
        // get filters based on selectedKeys
        const activeFiltersIds = this._selectedFilters$.getValue().map(filter => filter.id);
        // get activeFilters that are not set in current selection
        const filtersToDelete = activeFiltersIds.filter((activeFilterId) => 
            selectedKeys.indexOf(activeFilterId) === -1
        );
        // get filters from current selection that are not yet set in store
        const filtersToAdd = selectedKeys.filter((selectedKey) =>
            activeFiltersIds.indexOf(selectedKey as Filter['id']) === -1
        );

        filtersToDelete.forEach((filterId) => this.deleteFilter(filterId));
        filtersToAdd.forEach((filterId) => this._addFilter(filterId as Filter['id']));
    }

    private _getEmptyFilterValue(filter: Filter): any {
        switch (filter.type) {
            case 'select-multiple': case 'range':
                return []
            case 'select-single-radio':
                return ''
            case 'date-range': case 'date-time-range':
                return undefined
        }
    }

    private _updateFilterValue(filterId: Filter['id'], value: any) {
        let filters = this._selectedFilters$.getValue(),
            match = filters.find((activeFilter) => activeFilter.id === filterId);

        if (!match) return
        match.value = value;
        this._selectedFilters$.next(filters)
    }

    private _addFilter(filterId: Filter['id']) {
        let activeFilters = this._selectedFilters$.getValue();
        
        // add placeholder filter if not available from baseFilters
        const newFilter: Filter = {
            id: filterId,
            label: this._filterRepo.getFilterName(filterId),
            value: [],
            type: 'select-multiple'
        }

        activeFilters.push(newFilter);
        // update active filters subject
        this._selectedFilters$.next(activeFilters);
    }

    public deleteFilter(filterId: Filter['id']) {
        let filters = this._selectedFilters$.getValue();
        const index = filters.map((filter) => filter.id).indexOf(filterId);
        filters.splice(index, 1)
        this._selectedFilters$.next(filters)
    }

    public resetFilter(filter: Filter) {
        this._updateFilterValue(filter.id, this._getEmptyFilterValue(filter))
    }

    public updateFilter(event: [Filter['id'], any]) {
        this._updateFilterValue(...event)
    }

    public createFilterSet() {
        let type = this.type$.getValue();
        let filterName = this.createFilterSetForm.value.name;
        let filterSetDefinition = this.createFilterSetForm.value.filters;
        let tenant: string | undefined;
        // if shared extract tenant
        if (type == 'shared' && filterSetDefinition) {
            const tenantDef = filterSetDefinition.find(obj => obj.id == 'tenant');
            if (tenantDef) {
                const { value } = tenantDef;
                if (Array.isArray(value)) {
                    tenant = value[0];
                } else {
                    tenant = value;
                    tenantDef.value = [value];
                }
            }
        }
        // abort if form invalid or required params missing
        if (this.createFilterSetForm.invalid || 
            !filterName || 
            !filterSetDefinition ||
            (type == 'shared' && !tenant)
        ) return;

        this.isLoading.set(true);
            
        // alert data from form
        const { toggled } = this.createFilterSetForm.value.alert!
    
        // send new filterSet to BE
        this._filterSetService.addFilterSets({
            body: [
                {
                    isShared: type === 'shared',
                    name: filterName,
                    filterSetDefinition,
                    ...(type === 'shared' && { tenant })
                }
            ]
        }).pipe(take(1)).subscribe({
            error: (res) => {
                this._notificationService.showError(
                    this._t(toggled ? 'FILTERS.INFO.ERROR_FILTER_SET_AND_ALERT' : 'FILTERS.INFO.ERROR_FILTER_SET'))
                this.onError.emit(res)
            },
            next: (res) => {
                const filterId = res[0].filterSetId;
                const filterName = decodeString(res[0].name);
                this._notificationService.showSuccess(this._t('FILTERS.INFO.FILTER_SET_CREATED', {content: filterName}))
                this._createAlert(filterId, filterName)
                this.isLoading.set(false);
                this._selectedFilters$.next([])
                this.createFilterSetForm.reset({
                    alert: {
                        toggled: false,
                        condition: 'gt',
                        numOfChargers: 10,
                        sendMail: false,
                        detailed: false
                    }
                })
                this.type$.next(this.type$.value)
                this._overviewCacheService.updateFilterSetsCache()
                this.onSuccess.emit()
            }
        })
    }

    public updateFilterSet() {
        let type = this.type$.getValue();
        let filterSetDefinition = this.createFilterSetForm.value.filters;
        let tenant: string | undefined;
        // if shared extract tenant and delete from definition
        if (type == 'shared' && filterSetDefinition) {
            const tenantDef = filterSetDefinition.find(obj => obj.id == 'tenant');
            if (tenantDef) {
                const { value } = tenantDef;
                if (Array.isArray(value)) {
                    tenant = value[0];
                } else {
                    tenant = value;
                    tenantDef.value = [value];
                }
            }
        }
        // abort if invalid or params missing
        if (
            this.createFilterSetForm.invalid ||
            !this._editFilterset ||
            !filterSetDefinition ||
            (type == 'shared' && !tenant)
        ) return;

        let filterName = this.createFilterSetForm.value.name ?? this._editFilterset.name,
            filtersetCopy: FilterSet | SharedFilterSet = structuredClone(this._editFilterset);

        filtersetCopy.name = filterName;
        filtersetCopy.filterSetDefinition = filterSetDefinition;
        if (filtersetCopy.isShared && 'tenant' in filtersetCopy && tenant) filtersetCopy.tenant = tenant;

        this.isLoading.set(true);
 
        this._filterSetService.updateFilterSet({
            filtersetid: filtersetCopy.filterSetId,
            body: filtersetCopy
        }).pipe(take(1)).subscribe({
            error: (res) => {
                this._notificationService.showError(this._t('FILTER_SETS_VIEW.INFO.UNABLE_TO_UPDATE'))
                this.onError.emit(res)
            },
            next: (res) => {
                this._notificationService.showSuccess(this._t('FILTER_SETS_VIEW.INFO.FILTER_SET_UPDATED', {content: filterName}))
                this.isLoading.set(false);

                // handle updates to alert on filterSet
                const alertForm = this.createFilterSetForm.getRawValue().alert;
                const { toggled, condition, numOfChargers, sendMail, detailed } = alertForm;
                if (this._editAlert) {
                    // alert might need to be deleted
                    if (toggled == false) {
                        this._alertService.deleteAlert({
                            alertId: this._editAlert.alertId
                        }).pipe(take(1)).subscribe({
                            error:  () => this._notificationService.showError(this._t('FILTER_SETS_VIEW.INFO.UNABLE_TO_DELETE_ALERT')),
                            next:   () => {
                                const alertName = decodeString(this._editAlert?.name ?? '')
                                this._notificationService.showSuccess(this._t('FILTER_SETS_VIEW.INFO.ALERT_DELETED.WITH_NAME', {name: alertName}))
                                this._overviewCacheService.updateAlertsCache()
                            }
                        })
                    } else {
                        // check for changes in alert
                        const hasChanged = 
                            this._editAlert.operator !== condition ||
                            this._editAlert.value !== numOfChargers ||
                            this._editAlert.sendMail !== sendMail ||
                            this._editAlert.detailed !== detailed;
                        if (hasChanged) {
                            // update existing alert
                            this._alertService.updateAlert({
                                alertId: this._editAlert.alertId,
                                body: {
                                    alertId: this._editAlert.alertId, // ???
                                    filterSetId: this._editFilterset!.filterSetId,
                                    operator: condition ?? undefined,
                                    value: numOfChargers ?? undefined,
                                    sendMail: sendMail ?? undefined,
                                    detailed: detailed ?? undefined
                                }
                            }).pipe(take(1)).subscribe({
                                error:  () => this._notificationService.showError(this._t('FILTER_SETS_VIEW.INFO.UNABLE_TO_UPDATE_ALERT')),
                                next:   () => {
                                    const alertName = decodeString(this._editAlert?.name ?? '')
                                    this._notificationService.showSuccess(this._t('FILTER_SETS_VIEW.INFO.ALERT_UPDATED', {content: alertName}))
                                    this._overviewCacheService.updateAlertsCache()
                                }
                            })
                        }
                    }
                } else if (toggled) {
                    // adds alert to existing filter set
                    this._createAlert(this._editFilterset!.filterSetId, this._editFilterset!.name)
                }

                this._selectedFilters$.next([])
                this.createFilterSetForm.reset()
                this._overviewCacheService.updateFilterSetsCache()
                this.onSuccess.emit()
            }
        })
    }

    private _createAlert(
        filterId: FilterSet['filterSetId'], 
        filterName: FilterSet['name']
    ) {
        const { toggled, condition, numOfChargers, sendMail, detailed } = this.createFilterSetForm.value.alert!;
        if (!toggled) return;

        this._alertService.addAlert({body: [{
            filterSetId: filterId,
            name: filterName!,
            operator: condition ?? undefined,
            value: numOfChargers ?? undefined,
            sendMail: sendMail ?? undefined,
            detailed: detailed ?? undefined
        }]}).pipe(
            take(1),
            catchError((res) => {
                this._notificationService.showError(this._t('FILTER_SETS_VIEW.INFO.UNABLE_TO_CREATE_ALERT', {content: filterName}))
                this.onError.emit(res)
                return EMPTY
            }),
            tap(() => {
                this._notificationService.showSuccess(this._t('FILTER_SETS_VIEW.INFO.ALERT_CREATED', {content: filterName}))
                this._overviewCacheService.updateAlertsCache()
            }),
            // update table after 5s again, to check new alert state
            delay(5000),
            tap(() => this._overviewCacheService.updateAlertsCache())
        ).subscribe()
    }

    public onSubmit() {
        // set to true to show possible filters errors
        this.triedFormSubmit = true;
        if (this.createFilterSetForm.invalid) return;
        // set back to false once form is valid
        this.triedFormSubmit = false;
        if (this.mode() == 'create') {
            this.createFilterSet()
        } else {
            this.updateFilterSet()
        }
    }
}
