import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, input, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, distinctUntilChanged, filter, map, pairwise, share, startWith, tap, withLatestFrom } from 'rxjs';

export type SelectMultipleOption = {
    label: string | number, 
    value: string | number, 
    isDisabled?: boolean,
    checked?: boolean
};

@Component({
  selector: 'app-select-multiple',
  template: `
    <app-dropdown-button
        [title]="title"
        [desc]="(buttonDescription$ | async) ?? ''"
        [empty]="((selectedValues$ | async) ?? []).length == 0"
        [info]="((selectedValues$ | async) ?? []).length"
        [bodyClass]="'overflow-hidden'"
        [maxBodyHeight]="bodyMaxHeight$ | async"
        [hideBtn]="hideBtn"
        [required]="required"
        [disabled]="disabled"
        [additionalInfo]="additionalInfo"
        [size]="size"
        [pos]="pos"
        [keepPosition]="keepPosition"
        (onClose)="dropdownOpen$.next(false)"
        (onEnter)="dropdownOpen$.next(false)"
        (onOpen)="dropdownOpen$.next(true)"
        (onSearch)="searchInput?.nativeElement.focus()"
    >
        <ng-container body>
            @if (vm$ | async; as vm) {
                @if (vm.allOptions.length > 0) {
                    @if (vm.showSearch) {
                        <div class="position-relative">
                            <input
                                type="text"
                                [placeholder]="'COMMON.SEARCH' | translate"
                                [value]="vm.searchQuery"
                                (input)="searchEvents$.next($event)"
                                #searchInput
                            >
                            <button
                                class="delete-search"
                                (click)="searchEvents$.next(null)"
                                tabindex="-1"
                            ></button>
                        </div>
                    }

                    @if (vm.showSelectAll) {
                        <a
                            class="select-all"
                            tabindex="0"
                            (click)="selectAllFilteredOptions(vm.options)"
                        >
                            {{ 'COMMON.SELECT_ALL' | translate }}
                        </a>
                    }

                    <cdk-virtual-scroll-viewport
                        [itemSize]="41"
                        class="scroll-viewport"
                        [class.has-select-all]="vm.showSelectAll"
                        [class.has-search]="vm.showSearch"
                        [class.has-search-and-select-all]="vm.showSearch && vm.showSelectAll"
                        [minBufferPx]="400"
                        [maxBufferPx]="600"
                        #scrollContainer
                    >
                        <div
                            *cdkVirtualFor="let option of vm.options; templateCacheSize: 15"
                            class="list-item"
                        >
                            <div class="list-select">
                                <input
                                    type="checkbox"
                                    [name]="title.split(' ').join('')"
                                    [id]="option.value+'-option'"
                                    [value]="option.value"
                                    [disabled]="!pseudoDisable && option.isDisabled"
                                    [class.disabled-selectable]="pseudoDisable && option.isDisabled"
                                    [checked]="option.checked || null"
                                    (change)="toggleSelectedValue$.next(option.value)"
                                >
                                <label
                                    [for]="option.value+'-option'"
                                >{{ option.label }}</label>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                    @if (vm.searchQuery && vm.options.length == 0) {
                        <span class="no-options-search text-center">
                            No options matching search "{{ vm.searchQuery }}" found
                        </span>
                    }
                } @else {
                    <div class="no-options">
                        <div class="material-icon">warning</div>
                        {{ 'APP_ERRORS.NO_OPTIONS_FOUND' | translate }}
                    </div>
                }
            }
        </ng-container>
    </app-dropdown-button>
  `,
  styleUrls: ['./select-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMultipleComponent {
    @ViewChild('scrollContainer') scrollContainer: CdkScrollable | undefined;
    @ViewChild('searchInput') searchInput: ElementRef | undefined;
    // Title of Dropdown
    @Input('title') title!: string;
    scrollOffsetTop: number = 0;
    // available options
    public allOptions$ = new BehaviorSubject<SelectMultipleOption[]>([]);
    @Input() set options(options: SelectMultipleOption[] | undefined | null) {
        this.allOptions$.next(options ?? []);
    };
    // add non selectable options to list
    private _disabledValues$ = new BehaviorSubject<(string | number)[]>([]);
    @Input() set disabledValues(values: (string | number)[] | undefined | null) {
        this._disabledValues$.next(values ?? []);
    };
    // all options combined (active, disabled, searched)
    private _options$: Observable<SelectMultipleOption[]>;
    // values of selected options
    @Input() set selectedValues(values: (string | number)[] | null) {
        // overwrites all selected values
        this.selectedValues$.next(values ?? [])
    }
    @Output() selectedValuesChange = new EventEmitter<{prev: (string | number)[], current: (string | number)[]}>();
    // sets value of observable
    public selectedValues$ = new BehaviorSubject<(string | number)[]>([]);
    // bulk add values to selection
    public addSelectedValues$ = new BehaviorSubject<(string | number)[] | null>(null);
    // toggle selected flag of option by its value
    public toggleSelectedValue$ = new BehaviorSubject<string | number | null>(null);
    // search events of input and button
    public searchEvents$ = new BehaviorSubject<Event | null>(null);
    // active search query in options dropdown
    public searchQuery$: Observable<string | null>;
    // manually setting max height if only a few options
    public bodyMaxHeight$: Observable<number | undefined>;
    // button description
    public buttonDescription$: Observable<string>;
    // dropdown state
    public dropdownOpen$ = new BehaviorSubject<boolean>(false);
    // handles comparing and emitting data
    private _requestEmission$ = new BehaviorSubject<(string | number)[]>([]);
    // view model to control display of options in dropdown
    public vm$: Observable<{
        allOptions: SelectMultipleOption[],
        options: SelectMultipleOption[],
        searchQuery: string | null,
        showSearch: boolean,
        showSelectAll: boolean
    }>

    // size of the button
    @Input('size') size: 'default' | 'small' = 'default';
    // whether dropdown should be below or to the side
    @Input('pos') pos: 'bottom' | 'side' = 'bottom';
    // if the button should be hidden
    @Input('hideBtn') hideBtn: boolean =  false;
    // whether this filter is disabled
    @Input('disabled') disabled: boolean = false;
    // whether this filter is required
    @Input('required') required: boolean | undefined;
    // text when no selection is active
    public emptyStateText = input<string | null>(null);
    // additional information on info icon in button
    @Input() additionalInfo: string | undefined;
    // pseudo-"disabled" disabledValues (style as disabled, but can still be de-/selected)
    @Input() pseudoDisable: boolean = false;
    // will keep initial position of dropdown content
    @Input() keepPosition: boolean = false;
    
    // helper to set and unlisten to events
    private _unlistenEvents: (() => void)[] = [];

    constructor(
        private _renderer: Renderer2,
        private _translate: TranslateService
    ) {
        // extract input value, handle events
        this.searchQuery$ = this.searchEvents$.pipe(
            map((event) => {
                const eventValue = event && (event.target as HTMLInputElement).value;
                return eventValue && eventValue.length > 0 ? eventValue : null
            }),
            share({connector: () => new ReplaySubject(1)})
        )

        // get all toggled options
        this.toggleSelectedValue$.pipe(
            takeUntilDestroyed(),
            withLatestFrom(this.selectedValues$),
            map(([toggleOption, selectedValues]) => {
                if (toggleOption === null) return
                // toggle options values in selectedValues
                const index = selectedValues.indexOf(toggleOption);
                if (index > -1) {
                    selectedValues.splice(index, 1)
                } else {
                    selectedValues.push(toggleOption)
                }

                this.selectedValues$.next(selectedValues)
            })
        ).subscribe()

        this.addSelectedValues$.pipe(
            takeUntilDestroyed(),
            withLatestFrom(this.selectedValues$),
            map(([addValues, selectedValues]) => {
                if (addValues == null) return

                if (addValues.every(value => selectedValues.includes(value))) {
                    selectedValues = selectedValues.filter(value => !addValues.includes(value));
                } else {
                    let uniqueValues = new Set([...selectedValues, ...addValues]);
                    selectedValues = [...uniqueValues];
                }

                this.selectedValues$.next(selectedValues);
            })
        ).subscribe()

        // map disabled values to allOptions and sort by disabledFlag
        // we do that in a seperate pipe to reduce calls when searching for options
        const preparedOptions$ = combineLatest({
            allOptions: this.allOptions$,
            disabledValues: this._disabledValues$,
            selectedValues: this.selectedValues$
        }).pipe(
            distinctUntilChanged(),
            map(({allOptions, disabledValues, selectedValues}) => {
                // set active & disabled flag
                allOptions.map((option) => {
                    option['checked'] = selectedValues.includes(option.value);
                    option['isDisabled'] = disabledValues.includes(option.value);
                    return option
                })

                // sort by disabled flag, then alphabetically (if str) or by size (if num)
                const boolHelper = [false, true];
                allOptions = [...allOptions.sort((optionA, optionB) => {
                    const isDisabledComparison = boolHelper.indexOf(optionA.isDisabled ?? true) - boolHelper.indexOf(optionB.isDisabled ?? false);
                    if (isDisabledComparison !== 0) {
                        return isDisabledComparison;
                    }
                    const labelA = optionA.label;
                    const labelB = optionB.label;           
                    if (typeof labelA === 'number' && typeof labelB === 'number') return labelA - labelB;
                    return labelA.toString().localeCompare(labelB.toString());
                })];

                return allOptions
            })
        )

        // provides all options to display in template
        this._options$ = combineLatest({
            preparedOptions: preparedOptions$,
            searchQuery: this.searchQuery$
        }).pipe(
            map(({preparedOptions, searchQuery}) => {
                // filter entries if search query
                if (searchQuery && searchQuery.length > 0) {
                    return preparedOptions.filter(option => {
                        const optLabel  = JSON.stringify(option.label).toLowerCase();
                        const query     = searchQuery.trim().toLowerCase();
                        return optLabel.includes(query)
                    })
                }
                return preparedOptions
            })
        )

        this.buttonDescription$ = combineLatest([
            this.selectedValues$,
            preparedOptions$,
            // listen to changes to both (potentially translated) emptyState text and language change
            // as there is a slight delay between the change and availability of the translattion
            toObservable(this.emptyStateText).pipe(startWith(null)),
            this._translate.onLangChange.pipe(startWith(null))
        ]).pipe(
            map(([selectedValues, options, emptyStateText]) => {
                if (selectedValues.length > 0) {
                    // map selected values with given options, then filter out null
                    let selectionLabels = selectedValues
                        .map((activeSelection) =>
                            options.find(option => option.value === activeSelection)?.label || null
                        ).filter(label => label != null) || [];
        
                    if (selectionLabels && selectionLabels.length > 0) {
                        return selectionLabels.join(', ');
                    }
                }
                return emptyStateText ?? this._translate.instant('COMMON.ALL');
            })
        )

        // resize dropdown depending on all available options
        this.bodyMaxHeight$ = this.allOptions$.pipe(
            // if less than 8 options, set max height to amt of options * height of selection element + search bar
            map((allOptions) => {
                if (!allOptions) return undefined;
                const length = allOptions.length;
                if (length == 0) return 50;
                if (length < 9) return length * 41 + 31;
                return undefined;
            })
        )

        const dropdownClosing$ = this.dropdownOpen$.pipe(
            pairwise(),
            map(([prev, current]) => {
                return prev === true && current === false;
            }),
            withLatestFrom(this.searchQuery$),
            map(([isClosing, searchQuery]) => {
                // if set, delete search query when closing dropdown
                if (isClosing && searchQuery) this.searchEvents$.next(null);

                return isClosing
            })
        );

        this.vm$ = combineLatest({
            allOptions: this.allOptions$,
            options: this._options$,
            searchQuery: this.searchQuery$.pipe(startWith(null))
        }).pipe(
            map(({allOptions, options, searchQuery}) => {

                const showSearch = allOptions.length > 9;
                const showSelectAll = (searchQuery !== null && options.length > 0) || (allOptions.length > 0 && allOptions.length < 50);

                return {
                    allOptions,
                    options,
                    searchQuery,
                    showSearch,
                    showSelectAll
                }
            })
        )

        // listen to dropdown state
        dropdownClosing$.pipe(
            takeUntilDestroyed(),
            withLatestFrom(this._options$),
            withLatestFrom(this.selectedValues$),
            tap(([[isClosing, options], selectedValues]) => {
                // always call requestEmission, as this keeps track of changes between inital state
                // after opening and before closing
                this._requestEmission$.next(structuredClone(selectedValues))
                if (isClosing) {
                    // closing
                    this._requestEmission$.next(structuredClone(selectedValues))

                    this.scrollOffsetTop = this.scrollContainer?.measureScrollOffset('top') || 0;

                    // fn called on close of dropdown, clear all event listeners
                    this._unlistenEvents.forEach((unlistener) => unlistener());
                } else if (isClosing === false) {
                    // opening
                    setTimeout(() => {
                        this.scrollContainer?.scrollTo({ top: this.scrollOffsetTop })
            
                        // foucs search input if available
                        if (options && options.length > 0 && this.searchInput) {
                            this.searchInput.nativeElement.focus()
                        }
                    }, 20);
            
                    this._unlistenEvents.push(
                        this._renderer.listen('document', 'keydown.space', (event: KeyboardEvent) => {
                            // dont prevent default while on input
                            if (event.target instanceof Element && event.target instanceof HTMLInputElement) return
                            // prevent scrolling on space, we only want to select checkbox in focus
                            event.preventDefault()
                        })
                    )
                }
            })
        ).subscribe()
        
        // takes batches of selected values to compare, emits if changes
        this._requestEmission$.pipe(
            takeUntilDestroyed(),
            // use pairwise to enable better comparison
            // we could use distinctUntilChanged(), but that has some drawbacks in this case, e.g. no comparison on first emission
            pairwise(),
            withLatestFrom(this.dropdownOpen$),
            // filter out opening emissions
            filter(([[prev, current], state]) => state !== false && !this._compareArrays(prev, current)),
            tap(([[prev, current]]) => {
                this.selectedValuesChange.emit({prev: prev, current: current})
            })
        ).subscribe()
    }

    selectAllFilteredOptions(options: SelectMultipleOption[]) {
        this.addSelectedValues$.next(options.map((option) => option.value));
    }

    // returns true if the two arrays are alike (ignores order)
    private _compareArrays(a: any[], b: any[]) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }
}
