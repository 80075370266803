import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Renderer2, Signal, ViewChild, computed, model, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { FilterSet, SharedFilterSet } from 'src/app/core/data-backend/models';
import { changeHeight } from 'src/app/core/helpers/animations';
import { decodeString } from 'src/app/core/helpers/utils.helper';

export type AdditionalListInformation = {
    value: string
    text: string
    group: 'variables' | 'filterset'
}

export type selectFilterOption = {
    label: string
    value: string
    checked?: boolean
    info?: string
    required?: boolean,
    columnIndex?: number
};

export type FilterSetColumnItem = (FilterSet | SharedFilterSet) & {
    columnIndex?: number;
};


@Component({
    selector: 'select-filters',
    template: `
    <app-dropdown-button
        [type]="'filter'"
        [kpiFilter]="view == 'kpi'"
        [desc]="selectedValsDesc"
        [info]="selectedFilters.length"
        [empty]="selectedFilters.length == 0"
        [chevron]="collapsible && chevron"
        [bodyClass]="bodyClasses.join(' ')"
        [heightFollowsChild]="true"
        [size]="'small'"
        [alignment]="alignment"
        [hideBtn]="hideButton"
        [disabled]="disabled"
        [open]="isOpen()"
        (onClose)="applyChanges()"
        (onEnter)="applyChanges()"
        (onOpen)="onOpen()"
        (onSearch)="searchInput.focus()"
    >
        <ng-container body>
            <div class="filters-body">
                @if ((showFilterSetSelection$ | async) == true) {
                    <div class="radio-button-group-wrapper">
                        @if (isOpen()) {
                            <radio-button-group
                                [type]="'line'"
                                [options]="groups()"
                                [activeSelection]="activeGroup"
                                (activeSelectionChange)="
                                    $event ? activeGroup = $event : '';
                                    updateShowMore()
                                "
                            >
                            </radio-button-group>
                        }
                    </div>
                }
                <div class="position-relative search-bar">
                    <input 
                        type="text"
                        [placeholder]="'COMMON.SEARCH' | translate"
                        (input)="searchEntries($event)"
                        #searchInput
                    >
                    <button
                        class="delete-search"
                        (click)="resetSearch()"
                        tabindex="-1"
                        type="button"
                    ></button>
                </div>
                @if (searchQuery && activeGroup === 'variables' && visibleFilterOptions && visibleFilterOptions.length > 0) {
                    <a 
                        class="select-all"
                        tabindex="0"
                        (click)="selectAllFilteredOptions()"
                    >
                        {{ 'COMMON.SELECT_ALL' | translate }}
                    </a>
                }
                <!-- Main List -->
                <div class="columns-cont">
                    <!-- Filter Vars -->
                    @if (activeGroup === 'variables') {
                        @for (column of visibleImportantFilterOptionsColumns; track column; let i = $index) {
                            <div class="options-column">
                                @for (option of visibleImportantFilterOptionsColumns[i]; track option.value) {
                                    <label class="justify-content-start align-items-center">
                                        <input 
                                            type="checkbox" 
                                            [name]="option.value"
                                            [checked]="option.checked"
                                            (change)="prepareSelection(option)"
                                        >
                                        <span
                                            class="title"
                                            [tooltip]="
                                                option.label.length > limit ?
                                                option.label : null
                                            "
                                            size="small"
                                            textAlign="left"
                                        >{{ 
                                            option.label.length > limit ? 
                                            option.label.substring(0, limit) + '...'
                                            : option.label 
                                        }}</span>
                                        @if (option.info) {
                                            <span
                                                class="info-icon"
                                                [tooltip]="option.info"
                                                size="small"
                                                textAlign="center"
                                            ></span>
                                        }
                                    </label>
                                }
                            </div>
                        }
                        <ng-container *ngTemplateOutlet="
                            filterOptionsPolling ? loadingState : emptyState; 
                            context: {$implicit: visibleFilterOptions, groupTitle: ('FILTERS.VARIABLE.OTHER' | translate)}">
                        </ng-container>
                    }
                    <!-- Own & Subscribed Filtersets -->
                    @if (activeGroup === 'filtersets') {
                        @for (column of visibleMyFilterSetsColumns; track column; let i = $index) {
                            <div class="options-column">
                                @for (filterSet of visibleMyFilterSetsColumns[i]; track filterSet.filterSetId) {
                                    <button
                                        class="filterset-button"
                                        (click)="selectFilterSet(filterSet)"
                                        type="button"
                                    >
                                        {{ filterSet.name }}
                                    </button>
                                }
                            </div>
                        }
                        <ng-container *ngTemplateOutlet="
                            filterSetsPolling ? loadingState : emptyState;
                            context: {$implicit: visibleFilterSets, groupTitle: ('FILTERS.FILTER_SET.OTHER' | translate)}">
                        </ng-container>
                    }
                </div>
                <!-- Expand Btn -->
                @if (showMoreVM$ | async; as showMoreVM) {
                    <button 
                        class="show-more"
                        type="button"
                        [class.expanded]="showMoreVM.expanded"
                        [disabled]="showMoreVM.disabled"
                        (click)="showMoreExpanded$.next(!showMoreVM.expanded)"
                    >{{ showMoreVM.text | translate }}</button>
                    <!-- Sub List-->
                    @if (showMoreVM.expanded) {
                        <div
                            class="columns-cont-sub"
                            [@changeHeight]
                        >
                            <!-- Filter Vars -->
                            @if (activeGroup === 'variables') {
                                @for (column of visibleOtherFilterOptionsColumns; track column; let i = $index) {
                                    <div class="options-column">
                                        @for (option of visibleOtherFilterOptionsColumns[i]; track option.value) {
                                            <label class="justify-content-start align-items-center">
                                                <input 
                                                    type="checkbox" 
                                                    [name]="option.value"
                                                    [checked]="option.checked"
                                                    (change)="prepareSelection(option)"
                                                >
                                                <span
                                                    class="title"
                                                    [tooltip]="
                                                        option.label.length > limit ?
                                                        option.label : null
                                                    "
                                                    size="small"
                                                    textAlign="left"
                                                >{{ 
                                                    option.label.length > limit ? 
                                                    option.label.substring(0, limit) + '...'
                                                    : option.label 
                                                }}</span>
                                                @if (option.info) {
                                                    <span
                                                        class="info-icon"
                                                        [tooltip]="option.info"
                                                        size="small"
                                                        textAlign="center"
                                                    ></span>
                                                }
                                            </label>
                                        }
                                    </div>
                                }
                            }
                            <!-- Other Filtersets -->
                            @if (activeGroup === 'filtersets') {
                                @for (column of visibleOtherFilterSetsColumns; track column; let i = $index) {
                                    <div class="options-column">
                                        @for (filterSet of visibleOtherFilterSetsColumns[i]; track filterSet.filterSetId) {
                                            <button
                                                class="filterset-button"
                                                (click)="selectFilterSet(filterSet)"
                                                type="button"
                                            >
                                                {{ filterSet.name }}
                                            </button>
                                        }
                                    </div>
                                }
                            }
                        </div>
                        @if (activeGroup === 'variables') {
                            <button
                                class="delete-all-btn"
                                type="button"
                                (click)="deleteAllOptions()"
                                [@changeHeight]="{ value: '*', params: { duration: '100ms' } }"
                            >
                                {{ 'COMMON.DELETE_ALL' | translate }}
                            </button>
                        }
                    }
                }
            </div>
        </ng-container>
    </app-dropdown-button>
    <!-- empty state -->
    <ng-template #emptyState let-contents let-groupTitle="groupTitle">
        @if (!contents || contents.length == 0) {
            <p class="empty-state">
                @if (searchQuery && searchQuery.trim().length > 0) {
                    {{ 'FILTERS.NO_MATCHING_CONTENT_AVAILABLE' | translate: {content: groupTitle, search: '"' + searchQuery.trim() + '"'} }}
                } @else {
                    {{ 'FILTERS.NO_CONTENT_AVAILABLE' | translate: {content: groupTitle} }}
                }
            </p>
        }
    </ng-template>
    <!-- loading state -->
    <ng-template #loadingState let-contents let-groupTitle="groupTitle">
        <!-- only show loading state when no data is available (first fetch), not while updating -->
        @if (!contents || contents.length == 0) {
            <p class="empty-state">
                {{ 'COMMON.LOADING.WITH_CONTENT' | translate: {content: groupTitle} }}
            </p>
        }
    </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./select-filters.component.scss'],
    animations: [changeHeight]
})
export class SelectFiltersComponent {
    @ViewChild('searchInput') searchInput: ElementRef | undefined;
    // disable "Filter Sets" container to only show available single filters
    private _showFilterSetSelection$ = new BehaviorSubject<boolean>(true);
    @Input() set showFiltersetSelection(value: boolean | null) {
        this._showFilterSetSelection$.next(value ?? true)
    };
    public showFilterSetSelection$: Observable<boolean> = this._showFilterSetSelection$.pipe(
        this._permService.getPermissionAndRerunOnPermissionUpdate('routes.filterSets'),
        map(({value, hasPermission}) => value && hasPermission)
    );
    // hides the button, main area remains clickable
    @Input() hideButton: boolean = false;
    // disables the button
    @Input() disabled: boolean = false;
    // view the selection is in
    @Input() view: 'overview' | 'map' | 'kpi' = 'overview';
    // alignment for the filter dropdown
    @Input() alignment: 'left' | 'right' = 'left';
    // whether this component is in a collipsable
    @Input() collapsible: boolean = false;
    // whether chevron of button should be shown or not
    chevron: boolean = false;
    // max amount of chars per label
    limit: number = 30;
    // controls disabled, expand states and text content
    private _showMoreDisabled$ = new BehaviorSubject<boolean>(false);
    public showMoreExpanded$ = new BehaviorSubject<boolean>(false);
    private _showMoreText$ = new BehaviorSubject<string>('COMMON.SHOW_MORE');
    public showMoreVM$: Observable<{
        expanded: boolean,
        disabled: boolean,
        text: string
    }>;
    // available single filter options
    private _allFilterOptions: selectFilterOption[] = [];
    @Input() set filterOptions(value: selectFilterOption[] | null) {
        // update all stored options
        this._allFilterOptions = value ?? [];
        // initially show all options
        this.visibleFilterOptions = value ?? [];
        this._updateOptions()
    }
    // filtered filter options to show in dropdown
    private _visibleFilterOptions: selectFilterOption[] = [];
    public visibleImportantFilterOptionsColumns: selectFilterOption[][] = [];
    public visibleOtherFilterOptionsColumns: selectFilterOption[][] = [];
    get visibleFilterOptions(): selectFilterOption[] {
        return this._visibleFilterOptions
    }
    set visibleFilterOptions(o: selectFilterOption[]) {
        this._visibleFilterOptions = o.filter(option => !option.required);
        // preserve previous column indexes if they exist
        const previousIndexes = new Map<string, number>();
        this._visibleFilterOptions.forEach(option => {
            if (option.columnIndex != undefined) {
                previousIndexes.set(option.value, option.columnIndex);
            }
        });
        // sort important options as in given list
        const visibleImportantFilterOptions = this._visibleFilterOptions.filter(
            option => this.importantFilterOptions.includes(option.value)
        ).sort((a, b) => {
            const indexA = this.importantFilterOptions.indexOf(a.value);
            const indexB = this.importantFilterOptions.indexOf(b.value);        
            return indexA - indexB;
        });
        // sort other options alphabetically
        const visibleOtherFilterOptions = this._visibleFilterOptions.filter(
            option => !this.importantFilterOptions.includes(option.value)
        );
        // split into three columns
        this.visibleImportantFilterOptionsColumns = this.splitIntoColumns(visibleImportantFilterOptions, previousIndexes);
        this.visibleOtherFilterOptionsColumns = this.splitIntoColumns(visibleOtherFilterOptions, previousIndexes);
    }

    // currently set filters
    public selectedFilters: string[] = [];
    @Input() set activeFilters(value: string[] | null) {
        // set selected filters to incoming value
        this.selectedFilters = value ?? [];
        // update chevron to keep in sync with collapsible
        this.chevron = this.selectedFilters.length > 0;
        // store a copy of current filter state before they get edited
        this._previousSelection = value ? structuredClone(value) : [];
        // update checked flag in current selection
        this._updateOptions()
    };
    @Output() activeFiltersChange = new EventEmitter<string[]>();

    // available filter sets
    @Input() set filterSets(filtersets: (FilterSet | SharedFilterSet)[] | null) {
        // decode escaped filterset names
        const filterSetsDecoded = filtersets?.map((filterSet) => ({
            ...filterSet,
            name: decodeString(filterSet.name)
        })) ?? [];
        // update all stored options
        this._allFilterSets = filterSetsDecoded;
        // initially show all options
        this.visibleFilterSets = filterSetsDecoded;
        this._updateOptions();
    }
    private _allFilterSets: FilterSetColumnItem[] = [];
    private _visibleFilterSets: FilterSetColumnItem[] = [];
    public visibleMyFilterSetsColumns: FilterSetColumnItem[][] = [];
    public visibleOtherFilterSetsColumns: FilterSetColumnItem[][] = [];
    get visibleFilterSets(): (FilterSet | SharedFilterSet)[] {
        return this._visibleFilterSets
    }
    set visibleFilterSets(o: (FilterSet | SharedFilterSet)[]) {
        this._visibleFilterSets = o;
        // preserve previous column indexes if they exist
        const previousIndexes = new Map<number, number>();
        this._visibleFilterSets.forEach(option => {
            if (option.columnIndex != undefined) {
                previousIndexes.set(option.filterSetId, option.columnIndex);
            }
        });
        // split filter sets in my and other
        const visibleMyFilterSets = this._visibleFilterSets.filter(
            option => !option.isShared || ('isSubscribed' in option && option.isSubscribed)
        )
        const visibleOtherFilterSets = this._visibleFilterSets.filter(
            option => option.isShared && (!('isSubscribed' in option) || !option.isSubscribed)
        )
        // split into three columns
        this.visibleMyFilterSetsColumns = this.splitIntoColumns(visibleMyFilterSets, previousIndexes);
        this.visibleOtherFilterSetsColumns = this.splitIntoColumns(visibleOtherFilterSets, previousIndexes);
    }

    // clicked filter set
    @Output() selectedFilterSet = new EventEmitter<FilterSet | SharedFilterSet>();

    // show additional info icon & tooltip text on certain options, mapped by value
    private _allAdditionalInformation: AdditionalListInformation[] = [];
    @Input() set additionalInformation(value: AdditionalListInformation[] | null) {
        this._allAdditionalInformation = value ?? [];
        this._updateOptions()
    }

    // loading states of each group
    private _filterOptionsPolling: boolean = false;
    @Input() set filterOptionsPolling(value: boolean) {
        this._filterOptionsPolling = value;
        this._updateButtonDesc();
    };
    get filterOptionsPolling() { return this._filterOptionsPolling };
    private _filterSetsPolling: boolean = false;
    @Input() set filterSetsPolling(value: boolean) {
        this._filterSetsPolling = value;
        this._updateButtonDesc();
    };
    get filterSetsPolling() { return this._filterSetsPolling }

    // classes for body of dropdown
    bodyClasses: string[] = [
        'select-filters-dropdown',
        'swiper-no-swiping',
        'overflow-hidden'
    ];

    // selectable groups
    public groups: Signal<{label: string, value: string}[]>;
    public activeGroup: string | number = 'variables';
    // keep track of last changes
    private _previousSelection: any[] | undefined;
    // string of selected values for button desc
    selectedValsDesc: string = this.filterOptionsPolling ? this.translate.instant('COMMON.LOADING.DEFAULT') + '...' : this.translate.instant('FILTERS.ADD_FILTER');
    // keep track of dropdown state, can be controlled by parent
    isOpen = model<boolean>(false);
    searchQuery: string | undefined;
    // helper to set and unlisten to events
    private _unlistenEvents: (() => void)[] = [];

    importantFilterOptions = [
        "chargerModel", "chargerVendor", "socketType", "currentType", "connectorId", "cpoName", "cpoSellerId", "tenant", "address", "postalCode", "city", "countryCode",
        "lastOverallState", "lastHeartbeatState", "lastChargingModelState", "lastErrorState", "lastHealthIndexValue", "lastConnectionDate", "lastErrorDate", "lastErrorStateCause", "lastError", "lastMaxPower", "lastOCPPMessageDate", "lastRegularChargingDate",
        "regularSessionsCountLastTwoWeeks", "regularSessionsCountLastDay", "shortSessionsCountLastTwoWeeks", "shortSessionsCountLastDay", "zeroSessionsCountLastTwoWeeks", "zeroSessionsCountLastDay", "invalidSessionsCountLastTwoWeeks", "invalidSessionsCountLastDay", "maxPowerLastTwoWeeks", "maxPowerLastDay", "maxCurrent", "maxEvsePower"
    ]

    constructor(
        public translate: TranslateService,
        private _renderer: Renderer2,
        private _permService: PermissionsService
    ) {
        this.showMoreVM$ = combineLatest({
            expanded: this.showMoreExpanded$,
            disabled: this._showMoreDisabled$,
            text: this._showMoreText$
        });

        const newLang = toSignal(this.translate.onLangChange);
        this.groups = computed(() => {
            newLang();
            return [
                {
                    label: this.translate.instant('FILTERS.VARIABLE.OTHER'),
                    value: 'variables',
                },
                {
                    label: this.translate.instant('FILTERS.FILTER_SET.OTHER'),
                    value: 'filtersets',
                }
            ];
        })
    }

    private _updateOptions() {
        this._updateSelectedFlag()
        this._updateAdditionalInformationMapping()
        this._updateButtonDesc()
    }

    // filter for all groups
    searchEntries(search: Event) {
        this.searchQuery = (search.target as HTMLInputElement).value;

        if (this.searchQuery && this.searchQuery.length > 0) {
            const query = this.searchQuery!.trim().toLowerCase();
            this.visibleFilterOptions = this._allFilterOptions.filter(option => {
                const optLabel  = JSON.stringify(option.label).toLowerCase();
                return optLabel.includes(query)
            })

            this.visibleFilterSets = this._allFilterSets.filter(option => {
                return option.name?.toLowerCase().includes(query)
            })
        } else {
            this.visibleFilterOptions = this._allFilterOptions;
            this.visibleFilterSets = this._allFilterSets;
            this.searchQuery = undefined;
        }

        this.updateShowMore();
    }

    resetSearch() {
        // delete search input, set input back in focus
        this.searchQuery = undefined;
        this.visibleFilterOptions = this._allFilterOptions;
        this.visibleFilterSets = this._allFilterSets;

        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.focus()
        }

        this.updateShowMore();
    }

    selectAllFilteredOptions() {
        let filtersToPrepare = this.visibleFilterOptions.filter(option => {
            return !this.selectedFilters.includes(option.value)
        });

        if (filtersToPrepare.length == 0) {
            this.visibleFilterOptions?.forEach((option) => this.prepareSelection(option))
        } else {
            filtersToPrepare?.forEach((option) => this.prepareSelection(option))
        }
    }

    deleteAllOptions() {
        this._allFilterOptions.forEach((option) => {
            if (option.checked) this.prepareSelection(option);
        })
        setTimeout(() => {
            this.applyChanges();
        }, 100);
    }

    // add or remove from retrieved initial array of values
    prepareSelection(option: selectFilterOption) {
        let index = this.selectedFilters.indexOf(option.value as string);
        option.checked = !option.checked
        if (index === -1) {
            this.selectedFilters.push(option.value as string)
        } else {
            this.selectedFilters.splice(index, 1)
        }
        this._updateButtonDesc()
        this.updateShowMore()
    }

    // updates whether the show more button should be enabled or disabled
    updateShowMore() {
        const selectedFilterOptions = this.selectedFilters.filter(filter => {
            const option = this.visibleFilterOptions.find(opt => opt.value === filter);
            return option && !option.required;
        });
        const noImportantVisibleOptions = this.visibleImportantFilterOptionsColumns.every(column => column.length === 0);
        const noOtherVisibleOptions = this.visibleOtherFilterOptionsColumns.every(column => column.length === 0);
        const noMyFilterSets = this.visibleMyFilterSetsColumns.every(column => column.length === 0);
        const noOtherFilterSets = this.visibleOtherFilterSetsColumns.every(column => column.length === 0);
        const hasSelectedFilters = selectedFilterOptions.length > 0;
        const hasNonImportantFilters = selectedFilterOptions.some(filter => !this.importantFilterOptions.includes(filter));
        const hasSearch = this.searchQuery !== undefined;

        const setShowMore = (showMore: boolean, disabled: boolean) => {
            this.showMoreExpanded$.next(showMore);
            this._showMoreDisabled$.next(disabled);
        };

        if (this.activeGroup == 'variables') {
            this._showMoreText$.next('COMMON.SHOW_MORE');

            if (hasSearch && !noOtherVisibleOptions) {
                setShowMore(true, false);
                return;
            }

            if (noOtherVisibleOptions) {
                setShowMore(false, true);
                return;
            }
            if (
                (noImportantVisibleOptions && !noOtherVisibleOptions) ||
                (hasSelectedFilters && hasNonImportantFilters)
            ) {
                setShowMore(true, true);
                return;
            }
            this._showMoreDisabled$.next(false);
        } else if (this.activeGroup == 'filtersets') {
            this._showMoreText$.next('FILTERS.SHARED_FILTER_SET.OTHER');

            if (hasSearch && !noOtherFilterSets) {
                setShowMore(true, false);
                return;
            }

            if (noOtherFilterSets) {
                setShowMore(false, true);
                return;
            }
            if (noMyFilterSets && !noOtherFilterSets) {
                setShowMore(true, true);
                return;
            }
            this._showMoreDisabled$.next(false);
        }
    }

    private _updateButtonDesc() {
        // regular description
        if (this.selectedFilters.length > 0) {
            // map selected values with given options, then filter out null
            let selectionLabels = this.selectedFilters
                .map((activeSelection) =>
                    this._allFilterOptions?.find(option => option.value === activeSelection)?.label || null
                ).filter(label => label != null) || [];

            if (selectionLabels && selectionLabels.length > 0) {
                this.selectedValsDesc = selectionLabels.join(', ');
            }
        } else {
            // If no values selected, the filter is inactive and will show all available values
            this.selectedValsDesc = this.filterOptionsPolling ? this.translate.instant('COMMON.LOADING.DEFAULT') + '...' : this.translate.instant('FILTERS.ADD_FILTER');
        }    
    }

    private _updateAdditionalInformationMapping() {
        if (this._allAdditionalInformation.length == 0) return
        const informationForVariables   = this._allAdditionalInformation.filter(x => x.group === 'variables');
        const informationForFilterSets  = this._allAdditionalInformation.filter(x => x.group === 'filterset');

        if (informationForVariables && informationForVariables.length > 0) {
            informationForVariables.forEach((info) => {
                const match = this._allFilterOptions.find((filterOption) => filterOption.value === info.value);
                if (match) match.info = info.text
            })
        }

        if (informationForFilterSets && informationForFilterSets.length > 0) {
            informationForFilterSets.forEach((info) => {
                const match = this._allFilterOptions.find((filterOption) => filterOption.value === info.value);
                if (match) match.info = info.text
            })
        }
    }

    // updates selected flag on options based on activeFilters
    private _updateSelectedFlag() {
        this._allFilterOptions = this._allFilterOptions.map((filterOption) => {
            filterOption.checked = this.selectedFilters.indexOf(filterOption.value as string) > -1;
            return filterOption
        })
    }

    // returns true if the two arrays are alike (ignores order)
    private _compareArrays(a: any[], b: any[]) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }

    // update filters value in repo when dropdown is closed
    applyChanges() {
        this.isOpen.set(false);
        // timeout 0 for angular animations
        setTimeout(() => this.showMoreExpanded$.next(false), 0);
        this.resetSearch();
        // compare changes
        if (!this._previousSelection || !this._compareArrays(this._previousSelection, this.selectedFilters)) {
            // emit with timeout of 0 to yield to browser and keep closing animation smooth
            setTimeout(() => this.activeFiltersChange.emit(this.selectedFilters), 0);
        }
        // fn called on close of dropdown, clear all event listeners
        this._unlistenEvents.forEach((unlistener) => unlistener());
    }

    selectFilterSet(filterSet: FilterSet | SharedFilterSet) {
        this.selectedFilterSet.emit(filterSet)
        this.isOpen.set(false)
    }

    onOpen() {
        this.isOpen.set(true);
        this.visibleFilterOptions = this._allFilterOptions;
        setTimeout(() => {
            this.updateShowMore();
            // foucs search input if available
            if (this.searchInput) this.searchInput.nativeElement.focus()
        }, 20);

        this._unlistenEvents.push(
            this._renderer.listen('document', 'keydown.space', (event: KeyboardEvent) => {
                // dont prevent default while on input
                if (event.target instanceof Element && event.target instanceof HTMLInputElement) return
                // prevent scrolling on space, we only want to select checkbox in focus
                event.preventDefault()
            })
        )
    }

    splitIntoColumns<T extends selectFilterOption | FilterSetColumnItem>(
        array: T[],
        previousIndexes: Map<string | number, number>
    ): T[][] {
        const columns: T[][] = [[], [], []];
        const partSize = Math.ceil(array.length / 3);
    
        array.forEach(option => {
            const key = 'value' in option ? option.value : option.filterSetId;
            const index = previousIndexes.get(key as string | number) ?? -1;
    
            if (index >= 0 && index < 3) {
                columns[index].push(option);
            } else {
                const colIndex = columns.findIndex(col => col.length < partSize);
                columns[colIndex].push(option);
                option.columnIndex = colIndex;
            }
        });
    
        return columns;
    }
}
